<template>
  <div class="units-container">
    <div class="project-units" v-if="openEditUnit !== true">
      <div class="project-units-header" v-if="$store.getters.project.house_type == 1">
        <span>UNITS</span> Here you can submit details for each unit.
      </div>
      <div class="project-units-header" v-else>
        <span>Houses</span> Here you can submit details for all houses.
      </div>
      <div class="project-units-list">
        <div class="units-list">
          <div class="units-list-blocks">
            <div class="units-list-header" v-if="$store.getters.project.house_type == 1">Unit Number</div>
            <div class="units-list-header" v-else>House Number</div>
            <div class="units-list-header">Status</div>
            <div class="units-list-header">Price <div style="margin-left: 5px" v-if="$store.getters.project.sale == 0">(Monthly)</div></div>
            <div class="units-list-header" v-if="$store.getters.project.sale == 0">Deposit</div>
            <div class="units-list-header" v-if="$store.getters.project.house_type == 1">Floor</div>
            <div class="units-list-header">Sq.ft (int.)</div>
            <div class="units-list-header">Sq.ft (ext.)</div>
            <div class="units-list-header">Bed</div>
            <div class="units-list-header">Bath</div>
            <div class="units-list-header">BMR</div>
            <div class="units-list-header"></div>
          </div>
          <div class="units-list-blocks warning" v-for="unit in $store.getters.project.unfloor_units" :key="unit.id">
            <div class="units-block-warning">The unit will not be displayed because the floor has been deleted. Delete the unit or select a different floor.</div>
            <div class="units-list-value">{{unit.unit_number}}</div>
            <div class="units-list-value">{{statusNames[unit.status].name}}</div>
            <div class="units-list-value">$ {{unit.price}}</div>
            <div class="units-list-value" v-if="$store.getters.project.sale == 0">{{unit.deposit != null? '$ ' + unit.deposit : '-'}}</div>
            <div class="units-list-value" v-if="$store.getters.project.house_type == 1">0</div>
            <div class="units-list-value">{{unit.int_sq}}</div>
            <div class="units-list-value">{{unit.ext_sq}}</div>
            <div class="units-list-value">{{unit.bad}}</div>
            <div class="units-list-value">{{unit.bath}}</div>
            <div class="units-list-value" v-if="unit.bmr == 1">Yes</div>
            <div class="units-list-value" v-if="unit.bmr == 0">No</div>
            <div class="units-list-value units-icons">
              <div class="units-edit-icon" @click="editUnit(unit)"></div>
              <div class="units-remove-icon" @click="prepareDelete"></div>
              <div class="delete-controls" style="display: none" data-id="delete-controls">
                <div class="delete-message">Delete unit?</div>
                <div class="control-buttons">
                  <div class="delete-accept control-button" @click="deleteUnFloorUnit(unit); cancelUnitDelete">Yes</div>
                  <div class="delete-decline control-button" @click="cancelUnitDelete">No</div>
                </div>
              </div>
            </div>
          </div>
          <template v-for="(floor, index) in this.$store.getters.project.floors">
            <div class="" :key="floor.id">
              <div class="units-list-blocks" :class="{warning: unit.floor == 0}" v-for="unit in floor.units" :key="unit.id">
                <div class="units-block-warning" v-if="unit.floor == 0">The unit will not be displayed because the floor has been deleted. Delete the unit or select a different floor.</div>
                <div class="units-list-value">{{unit.unit_number}}</div>
                <div class="units-list-value">{{createStatus(unit)}}</div>
                <div class="units-list-value">$ {{unit.price}}</div>
                <div class="units-list-value" v-if="$store.getters.project.sale == 0">{{unit.deposit != null? '$ ' + unit.deposit: '-'}}</div>
                <div class="units-list-value" v-if="$store.getters.project.house_type == 1">{{index+ 1}}</div>
                <div class="units-list-value">{{unit.int_sq}}</div>
                <div class="units-list-value">{{unit.ext_sq}}</div>
                <div class="units-list-value">{{unit.bad}}</div>
                <div class="units-list-value">{{unit.bath}}</div>
                <div class="units-list-value" v-if="unit.bmr == 1">Yes</div>
                <div class="units-list-value" v-if="unit.bmr == 0">No</div>
                <div class="units-list-value units-icons">
                  <div class="units-edit-icon" @click="editUnit(unit)"></div>
                  <div class="units-remove-icon" v-if="$store.getters.project.role !== 'observer'" @click="prepareDelete"></div>
                  <div class="delete-controls" style="display: none" data-id="delete-controls">
                    <div class="delete-message">Do you really want to delete a unit?</div>
                    <div class="control-buttons">
                      <div class="delete-accept control-button" @click="deleteUnit(unit); cancelUnitDelete">Yes</div>
                      <div class="delete-decline control-button" @click="cancelUnitDelete">No</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
<!--          <div class="" v-if="$store.getters.project.house_type == 2">
            <div class="units-list-blocks" v-if="$store.getters.project.lot_info != null">
              <div class="units-list-value">{{$store.getters.project.lot_info.unit_number}}</div>
              <div class="units-list-value">{{statusNames[$store.getters.project.lot_info.status].name}}</div>
              <div class="units-list-value">{{$store.getters.project.lot_info.price}} $</div>
              <div class="units-list-value">{{$store.getters.project.lot_info.int_sq}}</div>
              <div class="units-list-value">{{$store.getters.project.lot_info.ext_sq}}</div>
              <div class="units-list-value">{{$store.getters.project.lot_info.bad}}</div>
              <div class="units-list-value">{{$store.getters.project.lot_info.bath}}</div>
              <div class="units-list-value" v-if="$store.getters.project.lot_info.bmr == 1">Yes</div>
              <div class="units-list-value" v-if="$store.getters.project.lot_info.bmr == 0">No</div>
              <div class="units-list-value units-icons">
                <div class="units-edit-icon" @click="editUnit($store.getters.project.lot_info)"></div>
                <div class="units-remove-icon" @click="prepareDelete"></div>
                <div class="delete-controls" style="display: none" data-id="delete-controls">
                  <div class="delete-message">Do you really want to delete a unit?</div>
                  <div class="control-buttons">
                    <div class="delete-accept control-button" @click="deleteUnit($store.getters.project.lot_info); cancelUnitDelete">Yes</div>
                    <div class="delete-decline control-button" @click="cancelUnitDelete">No</div>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
        </div>
      </div>
      <div class="project-units-control" v-if="$store.getters.project.role !== 'observer'">
        <div class="logo-upload" @click="addUnit()">Add a Unit</div>
      </div>
    </div>
    <EditUnitModal :unit="templateUnit" v-else></EditUnitModal>
  </div>
</template>

<script>
  import EditUnitModal from '@/components/edit/EditUnitModal.vue'
  import constants from "../../Constants";
  export default {
    name: 'ProjectUnits',
    components: {
      EditUnitModal,
    },
    data: ()=>({
      statusNames: constants.STATUS_OPTIONS,
      oldUnit: {},
      templateUnit: JSON.parse(JSON.stringify(constants.STANDART_UNIT)),
      units: [],
      openAddUnit: false,
      openEditUnit: false,
    }),
    methods: {
      createStatus(unit) {
        if(unit.status == 2 && this.$store.getters.project.sale == 0) {
          unit.status = 3;
          return this.statusNames[unit.status].name;
        } else {
          return this.statusNames[unit.status].name;
        }
      },
      resetTemplateUnit() {
        this.templateUnit = JSON.parse(JSON.stringify(constants.STANDART_UNIT));
      },
      openPopup() {
        this.openAddUnit = true;
      },
      prepareDelete(e){
        let container = e.target.parentNode;
        container = container.querySelector('[data-id="delete-controls"]');
        container.style.display = 'flex';
      },
      cancelUnitDelete(e){
        let container = e.target.parentNode.parentNode.parentNode;
        container = container.querySelector('[data-id="delete-controls"]');
        container.style.display = 'none';
      },
      deleteUnit(unit) {
        let containers = document.querySelectorAll('[data-id="delete-controls"]');
        for (var i = 0; i < containers.length; i++) {
          containers[i].style.display = 'none';
        }
        let unit_id = unit.id;
        for (let i = 0; i < this.units.length; i++) {
          if (this.units[i] === unit) {
            this.units.splice(i, 1);
          }
        }
        let data = new FormData();
        let user = JSON.parse(localStorage.getItem('maagio_user'));
        let token = localStorage.getItem('token');
        data.append('user_id', user.uid);
        data.append('token', token);
        data.append('unit_id', unit_id);
        data.append('house_type', this.$store.getters.project.house_type);
        let obj = this;
        $.ajax({
          url: constants.BACKEND_URL + 'unit/delete-unit',
          type: 'POST', // важно!
          data: data,
          cache: false,
          dataType: 'json',
          processData: false,
          contentType: false,
          success: function (respond, status, jqXHR) {
            if (respond.ok === 1) {
              if(respond.house_type == 2) {
                obj.$store.getters.project.lot_info = null;
                return;
              }
              for (let i = 0; i < obj.$store.getters.project.floors.length; i++) {
                for (let n = 0; n < obj.$store.getters.project.floors[i].units.length; n++) {
                  if (obj.$store.getters.project.floors[i].units[n] === unit) {
                    obj.$store.getters.project.floors[i].units.splice(n, 1);
                  }
                }
              }
              obj.$store.getters.project.units = obj.units;
            } else {
              console.log('ОШИБКА: ' + respond.data);
            }
          },
          error: function (jqXHR, status, errorThrown) {
            console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
          }
        });
      },
      deleteUnFloorUnit(unit){
        let containers = document.querySelectorAll('[data-id="delete-controls"]');
        for (var i = 0; i < containers.length; i++) {
          containers[i].style.display = 'none';
        }
        let unit_id = unit.id;
        for (let i = 0; i < this.units.length; i++) {
          if (this.units[i] === unit) {
            this.units.splice(i, 1);
          }
        }
          for (let n = 0; n < this.$store.getters.project.unfloor_units.length; n++) {
            if (this.$store.getters.project.unfloor_units[n] === unit) {
              this.$store.getters.project.unfloor_units.splice(n, 1);
            }
          }
        let data = new FormData();
        let user = JSON.parse(localStorage.getItem('maagio_user'));
        let token = localStorage.getItem('token');
        data.append('user_id', user.uid);
        data.append('token', token);
        data.append('unit_id', unit_id);
        let obj = this;
        $.ajax({
          url: constants.BACKEND_URL + 'unit/delete-unit',
          type: 'POST', // важно!
          data: data,
          cache: false,
          dataType: 'json',
          processData: false,
          contentType: false,
          success: function (respond, status, jqXHR) {
            if (respond.ok === 1) {

            } else {
              console.log('ОШИБКА: ' + respond.data);
            }
          },
          error: function (jqXHR, status, errorThrown) {
            console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
          }
        });
      },
      addUnit() {
        this.templateUnit = JSON.parse(JSON.stringify(constants.STANDART_UNIT));
        this.openEditUnit = true;
      },
      editUnit(unit) {
        this.templateUnit = JSON.parse(JSON.stringify(unit));
       for(var i = 0; i < this.$store.getters.project.floors.length; i++) {
         for(var n = 0; n < this.$store.getters.project.floors[i].units.length; n++) {
           if(this.$store.getters.project.floors[i].units[n] === unit) {
             this.$store.getters.project.floors[i].units[n].terrace = Number.parseInt(this.$store.getters.project.floors[i].units[n].terrace);
             this.$store.getters.project.floors[i].units[n].loft = Number.parseInt(this.$store.getters.project.floors[i].units[n].loft);
             this.$store.getters.project.floors[i].units[n].bmr = Number.parseInt(this.$store.getters.project.floors[i].units[n].bmr);
             this.$store.getters.project.floors[i].units[n].parking = Number.parseInt(this.$store.getters.project.floors[i].units[n].parking);
             this.$store.getters.project.floors[i].units[n].den = Number.parseInt(this.$store.getters.project.floors[i].units[n].den);
             this.$store.getters.project.floors[i].units[n].outdoor_areas = Number.parseInt(this.$store.getters.project.floors[i].units[n].outdoor_areas);
             this.templateUnit.floor = i + 1;
             break;
           }
         }
       }
        this.openEditUnit = true;
      }
    },
    mounted(){
      console.log(this.$store.getters.project.sale);
    }
  }
</script>
<style scoped lang="less">
.project-units {
  padding-top: 50px;
  & .project-units-header {
    font-size: 20px;
    padding-bottom: 30px;
    color: #828282;
    & span {
      padding-bottom: 6px;
      display: block;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      text-transform: uppercase;
      color: #ABADAE;
      font-family: RobotoRegular, sans-serif;
    }
  }
  & .project-units-list {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #515558;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  & .project-units-control {
    display: flex;
    justify-content: flex-end;
    padding-top: 40px;
    & .logo-upload {
      color: #FFFFFF;
      font-size: 22px;
      margin-left: 0;
      margin-right: 0;
      padding: 5px 50px;
      background: #2C79D3;
      border-radius: 5px;
    }
  }
  & .fullscreen-modal {
    padding: 50px 30px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(46, 51, 54, 0.56);
    z-index: 9999;
    overflow: auto;
  }

  & .project-units-list {
    & .units-list {
      & .units-list-blocks.warning {
        color: #ABADAE;
      }
      & .units-list-blocks {
        position: relative;
        & .units-block-warning {
          position: absolute;
          top: 4px;
          font-size: 12px;
          width: 100%;
          text-align: center;
          color: red;
        }
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #828282;
        & .units-list-value {
          display: flex;
          justify-content: center;
          width: 9%;
          flex: none;
          color: #ABADAE;
        }
        & .units-list-header {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 9%;
          color: #ABADAE;
        }
        & .units-list-value.units-icons {
          position: relative;
          display: flex;
          .delete-controls {
            position: absolute;
            margin-top: -12px;
            width: 100%;
            flex-direction: column;
            height: 50px;
            background-color: #515558;
            .delete-message {
              color: red;
              font-size: 12px;
              padding-bottom: 5px;
              text-align: center;
            }
            .control-buttons {
              display: flex;
              justify-content: space-between;
            }
            .control-button {
              margin-left: 20px;
              margin-right: 20px;
              cursor: pointer;
            }
            .delete-accept {
              color: #ABADAE;
            }
            .delete-decline {
              color: #ABADAE;
            }
          }
          & .units-edit-icon {
            background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.5008 6.71975L13.8824 2.14923L15.4038 0.625726C15.8203 0.208575 16.3322 0 16.9392 0C17.5463 0 18.0578 0.208575 18.4736 0.625726L19.995 2.14923C20.4115 2.56638 20.6288 3.06987 20.647 3.65968C20.6651 4.2495 20.4658 4.75262 20.0493 5.16904L18.5008 6.71975ZM16.9251 8.32488L5.40643 19.86H0.788086V15.2351L12.3068 3.69995L16.9251 8.32488Z' fill='%23ABADAE'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            width: 22px;
            height: 17px;
            background-size: contain;
            cursor: pointer;
          }
          & .units-remove-icon {
            width: 22px;
            height: 17px;
            margin-right: 20px;
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='23' viewBox='0 0 18 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22943 20.0071C1.22943 21.3826 2.33591 22.508 3.68829 22.508H13.5237C14.8761 22.508 15.9826 21.3826 15.9826 20.0071V5.00178H1.22943V20.0071ZM3.68829 7.50267H13.5237V20.0071H3.68829V7.50267ZM12.909 1.25044L11.6796 0H5.53243L4.303 1.25044H0V3.75133H17.212V1.25044H12.909Z' fill='%23ABADAE'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
