<template>
  <div>
    1
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>
