import constants from "@/Constants";
const emptyProject = {
  name: '',
  floors: [],
  galleries: [],
  house_type: 1,
  published: 0,
  special_link: '',
  id: '',
  logo: '',
  map: '',
  markers: {},
  template: {id: 4},
  show_price: 0,
  sale: 1,
  project_logo: '',

};

export default {
  state: {
    project: {
      name: '',
      floors: [],
      galleries: [],
      house_type: 1,
      published: 0,
      special_link: '',
      id: '',
      logo: '',
      map: '',
      markers: {},
      show_price: 0,
      template: {id: 4},
      sale: 1,
      project_logo: ''

    },
  },
  getters: {
    project: (state) => {
      return state.project;
    },
  },
  mutations: {
    project_take(state, payload) {
      for (const key in payload) {
        if (state.project.hasOwnProperty(key)) {
          state.project[key] = payload[key];
        }
      }
    },
    project_floor_add(state, payload) {
      state.project.floors.push(payload);
    },
    project_create_new(state) {
      state.project = JSON.parse(JSON.stringify(emptyProject));
    },
    project_update(state, payload) {
      for (const key in payload) {
        if (state.project.hasOwnProperty(key)) {
          state.project[key] = payload[key];
        }
      }
    }
  },
  actions: {
    PROJECT_UPLOAD_FLOOR_PHOTO(context, payload) {
      this.loaded = true;
      let data = new FormData();
      let user = JSON.parse(localStorage.getItem('maagio_user'));
      let token = localStorage.getItem('token');
      data.append('user_id', user.uid);
      data.append('token', token);
      data.append('floor_id', payload.floor.id);
      data.append('project_id', context.getters.project.id);
      data.append('image', payload.file);
      let obj = this;
      $.ajax({
        url: constants.BACKEND_URL + 'floor/upload-photo',
        type: 'POST', // важно!
        data: data,
        cache: false,
        dataType: 'json',
        processData: false,
        contentType: false,
        success: (respond, status, jqXHR) => {
          let reader = new FileReader();
          reader.addEventListener("load", function () {
            payload.floor.preview = reader.result;
            payload.floor.image = payload.file;
          }.bind(this), false);
          if (payload.file) {
            if (/\.(jpe?g|png)$/i.test(payload.file.name)) {
              reader.readAsDataURL(payload.file);
            }
          }
          obj.loaded = false;
          if (respond.ok === 1) {

          } else {
            console.log('ОШИБКА: ' + respond.data);
          }
        },
        error: function (jqXHR, status, errorThrown) {
          obj.loaded = false;
          console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
        }
      });
    },
    PROJECT_FLOOR_CHANGE_NUMBER(context, payload) {
      payload.floor.number = payload.number;
    },
    PROJECT_FLOOR_ADD(context, payload) {
      context.commit('project_floor_add', payload);
    },
    PROJECT_UPDATE(context, payload) {
      context.commit('project_update', payload)
    },
    PROJECT_CREATE_NEW(context) {
      context.commit('project_create_new')
    },
    PROJECT_REMOVE_ACCESS(context, payload) {
      let data = new FormData();
      data.append('access_id', payload.access_id);
      return $.ajax({
        url: constants.BACKEND_URL + 'project/remove-access',
        type: 'POST',
        data: data,
        cache: false,
        dataType: 'json',
        processData: false,
        contentType: false,
        success: function (respond, status, jqXHR) {
          if (respond.ok === 1) {
            console.log(respond);
          }
          else {
            console.log('ОШИБКА: ' + respond.data);
          }
        },
        error: (jqXHR, status, errorThrown) => {
          this.loaded = false;
          console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
        }
      });
    },
    PROJECT_CREATE_ACCESS(context, payload) {
      let data = new FormData();
      let token = localStorage.getItem('token');
      data.append('project_id', payload.project_id);
      data.append('email', payload.email);
      data.append('password', payload.password);
      data.append('role', payload.role);
      data.append('token', token);
      return $.ajax({
        url: constants.BACKEND_URL + 'project/create-access',
        type: 'POST',
        data: data,
        cache: false,
        dataType: 'json',
        processData: false,
        contentType: false,
        success: function (respond, status, jqXHR) {
          if (respond.ok === 1) {
            console.log(respond);
          }
          else {
            console.log('ОШИБКА: ' + respond.data);
          }
        },
        error: (jqXHR, status, errorThrown) => {
          this.loaded = false;
          console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
        }
      });
    },
    PROJECT_SAVE(context) {
      return new Promise((resolve, reject) => {
        let data = new FormData();
        let user = JSON.parse(localStorage.getItem('maagio_user'));
        let token = localStorage.getItem('token');
        if (context.getters.project.id != '' && context.getters.project.id != undefined) {
          data.append('project_id', context.getters.project.id);
        }
        data.append('user_id', user.uid);
        data.append('token', token);
        data.append('logo', context.getters.project.logo);
        data.append('name', context.getters.project.name);
        data.append('sale', context.getters.project.sale);
        data.append('house_type', context.getters.project.house_type);
        data.append('show_price', context.getters.project.show_price);
        data.append('template_id', context.getters.project.template.id);
        $.ajax({
          url: constants.BACKEND_URL + 'project/update-overview',
          type: 'POST', // важно!
          data: data,
          cache: false,
          dataType: 'json',
          // отключаем обработку передаваемых данных, пусть передаются как есть
          processData: false,
          // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
          contentType: false,
          // функция успешного ответа сервера
          success: function (respond, status, jqXHR) {
            if (respond.ok === 1) {
              context.commit('project_update', {
                logo: respond.project.logo_link,
                name: respond.project.name,
                id: respond.project.id,
                house_type: respond.project.house_type,
              });
              resolve(respond.project);
            } else {
              console.log('ОШИБКА: ' + respond.data);
              reject('Error in response');
            }
          },
          // функция ошибки ответа сервера
          error: (jqXHR, status, errorThrown) => {
            console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
            reject('AJAX request error');
          }
        });
      });
    },
    PROJECT_UPLOAD_LOGO(context, payload) {
      if (!payload || !context.getters.project.id) {
        return;
      }
      let data = new FormData();
      let user = JSON.parse(localStorage.getItem('maagio_user'));
      let token = localStorage.getItem('token');
      if (context.getters.project.id != '' && context.getters.project.id != undefined) {
        data.append('project_id', context.getters.project.id);
      }
      data.append('user_id', user.uid);
      data.append('token', token);
      data.append('logo', payload);
      data.append('name',  context.getters.project.name);
      data.append('sale', context.getters.project.sale);
      data.append('house_type', context.getters.project.house_type);
      data.append('template_id',context.getters.project.template.id);
      $.ajax({
        url: constants.BACKEND_URL + 'project/update-overview',
        type: 'POST', // важно!
        data: data,
        cache: false,
        dataType: 'json',
        // отключаем обработку передаваемых данных, пусть передаются как есть
        processData: false,
        // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
        contentType: false,
        // функция успешного ответа сервера
        success: function (respond, status, jqXHR) {
          if (respond.ok === 1) {
            context.commit('project_update', {
              logo: respond.project.logo_link
            })
          }
          // ОК - файлы загружены
          if (typeof respond.error === 'undefined') {
            // выведем пути загруженных файлов в блок '.ajax-reply'
            var files_path = respond.files;
            var html = '';
            $.each(files_path, function (key, val) {
              html += val + '<br>';
            })

            $('.ajax-reply').html(html);
          }
          // ошибка
          else {
            console.log('ОШИБКА: ' + respond.data);
          }
        },
        // функция ошибки ответа сервера
        error: (jqXHR, status, errorThrown)=> {
          this.loaded = false;
          console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
        }
      });
    },
    PROJECT_TAKE_BY_LINK(context, payload) {
      $.ajax({
        url         : constants.BACKEND_URL + 'project/show?project=' + payload,
        type        : 'GET', // важно!
        cache       : false,
        dataType    : 'json',
        success     : ( respond, status, jqXHR ) => {
          if(respond.data.ok !== 0) {
            context.commit('project_take', respond.data);
          }
        },
      });
    },
    PROJECT_TAKE(context) {
      let user = JSON.parse(localStorage.getItem('maagio_user'));
      let token = localStorage.getItem('token');
      let project_id  = window.location.href.split('project_id=');
      if(project_id.length === 1) {
        context.commit('project_take', emptyProject);
        return;
      }
      project_id = project_id[project_id.length - 1];
      let data = {
        user_id: user.uid,
        token: token,
        project_id: project_id,
      };

      $.ajax({
        url         : constants.BACKEND_URL + 'project/take',
        type        : 'GET', // важно!
        data        : data,
        cache       : false,
        dataType    : 'json',
        success     : ( respond, status, jqXHR ) => {
          if(respond.data.ok !== 0) {
            context.commit('project_take', respond.data);
          }
        },
      });
    },
  }
}
