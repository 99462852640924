<template>
    <div class="project-publish">
        <div class="publish-header">
          Almost done, hit publish to create a sharable link to the application!
        </div>
      <div class="publish-subheader">
        This is your link of the app. Until you publish the app, this link will remain private
      </div>
        <div class="publish-container">
            <div class="personal-link" :class="{copied: linkCopied}" id="personal-link">
              <div class="personal-link-text">
                <template v-if="personalLink">{{personalLink}}</template>
                <template v-else>Press Publish to get the link </template>
              </div>
              <div class="copy-icon pointer" v-if="$store.getters.project.published === 1" @click="copyLink"></div>
            </div>
            <div class="copy-link" @click="publish" v-if="$store.getters.project.role !== 'observer'">
                <span v-if="$store.getters.project && $store.getters.project.published != 1">Publish</span>
              <span v-else>Unpublish</span>
            </div>
        </div>
      <div class="tooltip" :class="{ show: linkCopied }">Link copied</div>
      <div class="publish-container">
        <div class="overview-checkbox" :class="{active: $store.getters.project.show_price == 1}">
          <label @click="changeShowPrice()">
            <div class="overview-checkbox-mask radio-mask">
              <div class="overview-checkbox-icon"></div>
            </div>
            Show unit prices</label>
        </div>
      </div>
      <div :key="accessLinks.length" style="margin-top: 40px" v-if="$store.getters.project.role === 'owner'">
        <div class="publish-subheader">Share access</div>
        <div class="publish-access" :key="link.id" v-for="link in accessLinks">
          <div class="access-text" >{{link.email}}</div>
          <div class="access-text">{{link.role}}</div>
          <div class="remove-icon" @click="removeAccess(link.id)"></div>
        </div>
        <div class="publish-container" v-if="!isCreateLink">
          <div style="margin-left: 0" class="copy-link" @click="isCreateLink = !isCreateLink">{{isCreateLink ? 'Cancel' : 'Create'}}</div>
        </div>
        <div v-if="isCreateLink" class="publish-container" style="margin-top: 20px; ">
          <div style="width: 30%">
            <div class="subcomponent-label">Email</div>
            <input @input="linkError = ''" type="text" min="1" v-model="linkEmail" class="project-input">
            <div class="" style="color: red; position: absolute;">{{ linkError }}</div>
          </div>
         <div style=" width: 30%">
           <div class="subcomponent-label">Password</div>
           <input type="text" min="1" v-model="linkPassword" class="project-input">
         </div>
          <div style="width: 20%">
            <div class="subcomponent-label">Role</div>
            <dropdown v-model="linkRole" @updateOption="changeRole" :selected="{val: 4, name: 'observer'}" :options="roleOptions"></dropdown>
          </div>
          <div class="publish-container" style="flex-wrap: nowrap">
            <div style="margin-left: 0; margin-right: 10px" class="copy-link" @click="createLink">Save</div>
            <div style="margin-left: 0" class="copy-link" @click="isCreateLink = !isCreateLink">Cancel</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import constants from "../../Constants";
  import Dropdown from "@/components/simple/dropdown.vue";

  export default {
    'name': 'ProjectPublish',
    components: { Dropdown },
    data: ()=>({
      role: '',
      accessLinks: [],
      roleOptions: [{name: 'admin', val: 2}, { name: 'editor', val: 3}, { name:  'observer', val:4}],
        speicalLink: '',
      isCreateLink: false,
      linkEmail: '',
      linkError: '',
      linkPassword: '',
      linkRole: 4,
      linkCopied: false,
    }),
    props: {
      context: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    computed: {
      personalLink() {
        if(this.$store.getters.project.published == 0) {
          return false;
        }
        let protocol = document.location.protocol;
        let host = document.location.host;
        return protocol + '//' + host + '/show/' + this.$store.getters.project.special_link;
      }
    },
    mounted() {
      let user = JSON.parse(localStorage.getItem('maagio_user'));
      this.accessLinks = this.$store.getters.project.accesses;
      this.user = user.role;
    },
    methods: {
      changeRole(e) {
        this.linkRole = e.val;
      },
      async removeAccess(id) {
        const result = await this.$store.dispatch('PROJECT_REMOVE_ACCESS', {access_id: id});
        if(result.ok == 1) {
          this.accessLinks = this.accessLinks.filter((item) => item.id != id)
        }
      },
      async createLink() {
        if(!this.linkEmail) {
          this.linkError = 'Email can not be blank'
          return;
        }
        if(!this.linkPassword) {
          this.linkError = 'Password can not be blank'
          return;
        }
        const result = await this.$store.dispatch('PROJECT_CREATE_ACCESS', {project_id: this.$store.getters.project.id, email: this.linkEmail, password: this.linkPassword, role: this.linkRole});
        if(result && result.ok == 1) {
          this.isCreateLink = false;
          this.linkPassword = '';
          this.linkEmail = '';
          this.accessLinks.push(result.data);
        } else {
          this.linkError = result.error;
        }
      },
      async changeShowPrice() {
        if(this.$store.getters.project.role === 'observer') {
          return;
        }
        if(this.pause) {
          return;
        }
        this.pause = true;
        await this.$store.dispatch('PROJECT_UPDATE', {show_price: this.$store.getters.project.show_price == 1 ? 0 : 1});
        await this.$store.dispatch('PROJECT_SAVE');
        this.pause = false;
      },
      publish(){
        let data = new FormData();
        let user = JSON.parse(localStorage.getItem('maagio_user'));
        let token = localStorage.getItem('token');
        data.append('user_id', user.uid);
        data.append('token', token);
        data.append('project_id', this.$store.getters.project.id);
        $.ajax({
          url: constants.BACKEND_URL + 'project/publish',
          type: 'POST', // важно!
          data: data,
          cache: false,
          dataType: 'json',
          processData: false,
          contentType: false,
          success: (respond, status, jqXHR) => {
            this.stopSave = false;
            if (respond.ok === 1) {
              this.$store.dispatch('PROJECT_UPDATE', {published: respond.published, special_link: respond.personal_link})
              if(this.$store.getters.project.published == 1) {
                let protocol = document.location.protocol;
                let host =  document.location.host;
                this.personalLink = protocol + '//' + host+'/show/' + this.$store.getters.project.special_link;
              }
            } else {
              console.log('ОШИБКА: ' + respond.data);
            }
          },
          error: (jqXHR, status, errorThrown)=> {
            this.stopSave = false;
            console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
          }
        });
      },
        copyLink: function () {
          if(this.linkCopied === true) {
            return;
          }
          var $tmp = $("<textarea>");
          $("body").append($tmp);
          $tmp.val($('#personal-link').text()).select();
          document.execCommand("copy");
          $tmp.remove();
          this.linkCopied = true;
          let obj = this;
          setTimeout(function () {
            obj.linkCopied = false;
          }, 2000)
        }
    }
  }
</script>
<style scoped lang="less">
.copy-icon {
  flex: none;
  height: 23px;
  width: 19px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='23' viewBox='0 0 20 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.625 22.75C2.00625 22.75 1.47638 22.5295 1.03538 22.0885C0.594377 21.6475 0.374252 21.118 0.375002 20.5V4.75H2.625V20.5H15V22.75H2.625ZM7.125 18.25C6.50625 18.25 5.97638 18.0295 5.53538 17.5885C5.09438 17.1475 4.87425 16.618 4.875 16V2.5C4.875 1.88125 5.0955 1.35138 5.5365 0.910377C5.9775 0.469377 6.507 0.249252 7.125 0.250002H17.25C17.8688 0.250002 18.3986 0.470502 18.8396 0.911502C19.2806 1.3525 19.5008 1.882 19.5 2.5V16C19.5 16.6188 19.2795 17.1486 18.8385 17.5896C18.3975 18.0306 17.868 18.2508 17.25 18.25H7.125ZM7.125 16H17.25V2.5H7.125V16Z' fill='%23ABADAE'/%3E%3C/svg%3E%0A");
}
.tooltip {
  opacity: 0;
  transition: opacity 0.5s;
  width: 100%;
  color: #2F80ED;
  font-size: 12px;
}

.tooltip.show {
  opacity: 1;
}
.project-publish {
  padding-top: 80px;
  min-height: 300px;
  .publish-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .copy-link.copied:hover {
      background-color: #4D4D4D;
      cursor: default;
    }
    .copy-link.copied {
      background-color: #4D4D4D;
    }
    .copy-link:hover {
      background-color: rgba(47, 128, 237, 0.84);
    }
    .copy-link {
      margin-left: 20px;
      white-space: nowrap;
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100px;
      text-align: center;
      background-color: #2F80ED;
      color: white;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      cursor: pointer;
    }
  }
  & .personal-link.copied {
    border: 1px solid #2F80ED;
  }
  & .personal-link {
    transition: border 0.5s;
    border: 1px solid #828282;
    color: white;
    max-width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-radius: 6px;
    height: 35px;
    font-size: 14px;
    padding: 9px 5px;
    width: 510px;
    min-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.publish-subheader {
  font-size: 16px;
  line-height: 19px;
  color: #ABADAE;
  padding-bottom: 20px;
}
.publish-header {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #ABADAE;
  padding-bottom: 10px;
  & span {
    font-family: RobotoBold, sans-serif;
  }
}
.personal-link-text {
  width: 100%;
}
.subcomponent-label {
  color: white;
}
.publish-access {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.access-text {
  padding: 10px;
  width: 40%;
  color: white;
}
.remove-icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='23' viewBox='0 0 18 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22943 20.0071C1.22943 21.3826 2.33591 22.508 3.68829 22.508H13.5237C14.8761 22.508 15.9826 21.3826 15.9826 20.0071V5.00178H1.22943V20.0071ZM3.68829 7.50267H13.5237V20.0071H3.68829V7.50267ZM12.909 1.25044L11.6796 0H5.53243L4.303 1.25044H0V3.75133H17.212V1.25044H12.909Z' fill='%23ABADAE'/%3E%3C/svg%3E%0A");
}
</style>
