import constants from "./Constants";
import axios from "axios";

window.VueHelper = {};
window.VueHelper.getImageBlob = function (url, callback = null) {
  var xhr = new XMLHttpRequest();

  xhr.onload = function() {
    if(callback != null) {
      callback(xhr.response);
    }
  };
  xhr.open('GET', url, true);
  xhr.send();
};
window.VueHelper.saveImage = function (id, url) {
  if(id == null || url == undefined) {
    return;
  }
  window.VueHelper.getImageBlob(url, function (res) {
    window.db.setImage(id, res);
  })
};
window.VueHelper.actualizeMark = (item) => {

}
window.VueHelper.actualizeMarks = (floors) => {
  for(var i = 0; i < floors.length; i++) {
    var floor = floors[i];
    var container = $('#floor-image' + floor.id);
    var height = container.parent().height();
    container.height(height);
    for(var n = 0; n < floors[i].units.length; n++) {
      var unit = floors[i].units[n];
      if(unit.unit_mark) {
        unit.unit_mark.natural_width = container.width() / 100 * unit.unit_mark.width;
        unit.unit_mark.natural_height = container.height() / 100 * unit.unit_mark.height;
        let fontSize = unit.unit_mark.natural_height / 3 / 2;
        let fontSize2 = unit.unit_mark.natural_width / 8;
        if (fontSize < fontSize2) {
          var min = fontSize;
        } else {
          var min = fontSize2;
        }
        if (min > 5) {
          unit.unit_mark.font_size = Number.parseInt(min);
        } else {
          unit.unit_mark.font_size = 5;
        }
      }
    }
  }
}
window.VueHelper.stablePassword = function () {
  var entityMap = {
    '#': '№',
    '&': '*',
  };
  function escapeHtml(string) {
    return String(string).replace(/[&#]/g, function (s) {
      return entityMap[s];
    });
  }
  return escapeHtml(val);
};
window.VueHelper.stablePassword = function (val) {
  var entityMap = {
    '#': '№',
    '&': '*',
  };
  function escapeHtml(string) {
    return String(string).replace(/[&#]/g, function (s) {
      return entityMap[s];
    });
  }
  return escapeHtml(val);
};
window.VueHelper.stableInput = function (val) {
    var entityMap = {
      '"': '`',
      "'": '`',
      '/': '/',
      '<': '',
      '>': '',
      '`': '`',
      '#': '№',
      '&': '',
    };
    function escapeHtml(string) {
      return String(string).replace(/[<>&#"'`\/]/g, function (s) {
        return entityMap[s];
      });
    }
    return escapeHtml(val);
};
window.VueHelper.changeManifest = function () {
  var man = document.querySelector('[rel="manifest"]');
  var href = window.location.href;
  var data = {};
  data.href = href;
  $.ajax({
    url: constants.BACKEND_URL + 'app/change-manifest',
    type: 'GET', // важно!
    data: data,
    dataType: 'json',
    success: function (respond, status, jqXHR) {
      if (respond.ok === 1) {
        var manifest = JSON.parse(respond.manifest);
        function download(content, fileName, contentType) {
          var file = new Blob([content], {type: contentType});
          man.setAttribute('href', URL.createObjectURL(file));
        }
        download(respond.manifest, 'manifest.json', 'application/json');
      }
    },
    // функция ошибки ответа сервера
    error: function (jqXHR, status, errorThrown) {
      console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
    }
  });
};
window.VueHelper.loadFloorsImages = function (floors_array) {
  for(var i = 0; i < floors_array.length; i++) {
    var floor = floors_array[i];
    if(floor.image_id != null && floor.image != null) {
      window.VueHelper.saveImage(floor.image_id, floor.image);
    }
    for(var n = 0; n < floor.units.length; n++) {
      var unit = floor.units[n];
      if(unit.image_id != null && unit.image != null) {
        window.VueHelper.saveImage(unit.image_id, unit.image);
      }
    }
  }
};

window.VueHelper.get = async function (url, callback, error) {
  $.ajax({
    url         : url,
    type        : 'GET',
    dataType    : 'json',
    success     : function( respond, status, jqXHR ){
      if(respond !== null) {
        if(respond.ok === 1) {
          callback(respond)
        }
      }
    },
    error: function( jqXHR, status, errorThrown ){
      error(this)
    }
  });
}
window.VueHelper.post = async function (url, data) {
  return axios.post(url, data).catch(error => {
    return error.response;
  }).then((response)=> {
    return false;
  });
}
