<template>
        <div class="new-gallery">
            <div>
                <div class="unit-create-header">Unit Photos</div>
                <div class="gallery-create" style="padding-top: 0;">
                    <div class="photos-container">
                        <div class="gallery-header"><span>Photos</span> Please add at least one photo.</div>
                        <div class="gallery-photos">
                            <div class="gallery-photo" v-for="(preview, key) in this.previews" :key="preview.image_link">
                                <img :src="preview.image_link" alt="">
                                <div class="photo-controls" v-if="$store.getters.project.role !== 'observer'">
                                    <div class="photo-controls-button" @click="removePreview(key)">REMOVE</div>
                                </div>
                            </div>
                            <div class="empty-floor" v-if="$store.getters.project.role !== 'observer'" onclick="document.getElementById('unit-images').click()">
                                <div class="empty-floor-text"><span class="empty-floor-plus">+</span> Add</div>
                            </div>
                            <form id="units-image" enctype="multipart/form-data">
                                <div class="form-group">
                                    <input v-if="$store.getters.project.role !== 'observer'" type="file" name="image" @change="uploadImage" style="display: none" multiple
                                           accept="image/png, image/jpeg" id="unit-images">
                                </div>
                                <input type="submit" style="display: none" class="btn btn-default">
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="unit-create-controls">
                <div class="project-page-button blue-button" @click="saveGallery" v-if="stopSave == false && $store.getters.project.role !== 'observer'">Save</div>
                <div class="project-page-button inactive-button" v-if="stopSave == true && $store.getters.project.role !== 'observer'">Save</div>
                <div class="project-page-button" @click="cancel">Cancel</div>
            </div>
        </div>
</template>

<script>
  import constants from '../../Constants';
  export default {
    'name': 'UnitGalleryModal',
    data: ()=>({
      stopSave: false,
      photos: [],
      previews: [],
    }),
    props: {
      gallery: {}
    },
    beforeMount() {
      this.photos = JSON.parse(JSON.stringify(this.$parent.templateUnit.photos)) || [];
      this.previews = JSON.parse(JSON.stringify(this.$parent.templateUnit.photos)) || [];
    },
    methods: {
      checkSave() {
        if (this.photos.length > 0) {
          return true;
        } else {
          return false;
        }
      },
      uploadImage(e) {
        for (let i = 0; i < e.target.files.length; i++) {
          this.file = e.target.files[i];
          let reader = new FileReader();
          reader.addEventListener("load", function () {
            this.previews.push({ image_link: reader.result });
            this.photos.push(e.target.files[i]);
          }.bind(this), false);
          if (this.file) {
            if (/\.(jpe?g|png)$/i.test(this.file.name)) {
              reader.readAsDataURL(this.file);
            }
          }
        }
      },
      removePreview(number) {
        this.photos.splice(number, 1);
        this.previews.splice(number, 1);
      },
      cancel() {
        this.photos = [];
        this.previews = [];
        this.$parent.unitGalleryModal = false;
      },
      saveGallery() {
        this.$parent.templateUnit.photos = this.photos;
        console.log(this.$parent.templateUnit);
        console.log(this.photos);
      this.$parent.unitGalleryModal = false;
      },
    },
  }
</script>
<style scoped lang="less">
                         .project-page-button {
                           margin-right: 10px;
                         }
.new-gallery {
  background-color: #fafafa;
  padding: 40px;
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  & .unit-create-header {
    color: #2F80ED;
    font-size: 24px;
    font-family: RobotoMedium, sans-serif;
  }
  & .gallery-create {
    padding-top: 40px;
    & .gallery-header {
      font-size: 20px;
      padding-bottom: 20px;
      color: #ABADAE;
      span {
        color: #ABADAE;
        font-family: RobotoMedium, sans-serif;
      }
    }
    & .project-input {
      max-width: 320px;
    }
    & .photos-container {
      padding-top: 30px;
      margin-left: -20px;
      margin-right: -20px;
      & .gallery-header {
        padding-bottom: 0;
        padding-left: 20px;
      }
      & .gallery-photos {
        display: flex;
        flex-wrap: wrap;
      }
      & .empty-floor {
        margin: 10px;
      }
      & .gallery-photo img {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 263px;
        cursor: pointer;
      }
      & .test-photo {
        background-image: url("/src/assets/img/no-image-project.png");
        width: 100%;
        height: 160px;
      }
      & .gallery-photo {
        display: flex;
        flex-direction: column;
        border: 1px solid #E0E0E0;
        margin: 10px;
        width: 397.2px;
        height: 331px;
        align-items: flex-start;
        justify-content: flex-start;
        border-radius: 10px;
        color: #4F4F4F;
        & .photo-controls {
          height: 65px;
          border-top: 1px solid #828282;
          position: relative;
          width: 100%;
          flex: none;
          padding-right: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          & .photo-controls-button:hover {
            color: #747474;
          }
          & .photo-controls-button {
            width: 50%;
            color: white;
            text-align: center;
            cursor: pointer;
            font-family: RobotoMedium, sans-serif;
          }
        }
      }
    }
  }
}
.new-gallery {
  background: #515558;
}
.empty-floor {
  display: flex;
  margin: 10px;
  width: 397px;
  border-radius: 10px;
  height: 331px;
  align-items: center;
  justify-content: center;
  border: 3px dashed #BDBDBD;
  color: #ABADAE;
  cursor: pointer;
}
.project-page-button {
  margin-left: 0;
}
.empty-floor-text {
  font-size: 35px;
  display: flex;
  align-items: center;
  color: #ABADAE;
  & .empty-floor-plus {
    text-align: center;
    margin-right: 10px;
    font-size: 51px;
    line-height: 67px;
  }
}
.remove-icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='23' viewBox='0 0 18 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22943 20.0071C1.22943 21.3826 2.33591 22.508 3.68829 22.508H13.5237C14.8761 22.508 15.9826 21.3826 15.9826 20.0071V5.00178H1.22943V20.0071ZM3.68829 7.50267H13.5237V20.0071H3.68829V7.50267ZM12.909 1.25044L11.6796 0H5.53243L4.303 1.25044H0V3.75133H17.212V1.25044H12.909Z' fill='%23ABADAE'/%3E%3C/svg%3E%0A");
}
</style>
