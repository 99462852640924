<template>
    <div class="project-floors">
        <div class="project-floor" v-for="(floor, key) in this.$store.getters.project.floors">
            <div class="floor-upload-button" v-show="floor.image == ''">
                <div class="empty-floor-text pointer" @click="uploadImage(key)">Upload {{ $store.getters.project.house_type == 2 ? 'site map' : 'floor plan' }}</div>
                <input type="file" :floor-number="key" accept="image/png, image/jpeg" @change="uploadFloorImage"
                       :id="'floor-image-' + key"
                       style="display: none">
            </div>
            <div class="floor-image" v-show="floor.image != ''" @click="uploadImage(key)"><img :src="floor.preview"
                                                                                               alt=""></div>
            <div class="floor-controls">
              <div class="floor-header">Site Plan {{key + 1}} Image {{floor.imageName}}</div>
              <div class="floor-control" v-if="$store.getters.project.role !== 'observer'">
                <div class="floor-button edit-icon" data-id="replaceButton" v-if="$store.getters.project.floors.length > 1" onclick="this.style.display = 'none';
                this.parentNode.getElementsByClassName('dropdown-container')[0].style.display = 'flex';">
                </div>
                <div class="dropdown-container" data-id="replaceList" style="display: none">
                  <specDropdown
                    :selected="object"
                    :elementid="key"
                    v-on:updateOption="selectNewFloor"
                    :placeholder="'Select an Item'"
                    :closeOnOutsideClick="true">
                  </specDropdown>
                </div>
                <div class="floor-button remove-button remove-icon" @click="checkFloorRemove(key)"></div>
                <div class="remove-floor-controls" style="display: none" :id="key + '-floor'">
                  <div class="remove-info" data-id="removeInfo" v-if="floor.units.length > 0">There are units ({{floor.units.length}}) on the floor. Would you like to delete them?</div>
                  <div class="remove-info" data-id="removeInfo" v-if="floor.units.length == 0">Would you like to delete this floor?</div>
                  <div class="remove-buttons">
                    <div class="remove-floor-button" data-id="accept" v-if="floor.units.length > 0" @click="removeFloor(key, true)">Yes</div>
                    <div class="remove-floor-button" data-id="decline" v-if="floor.units.length > 0" @click="removeFloor(key, false)">No</div>
                    <div class="remove-floor-button" data-id="decline" v-if="floor.units.length == 0" @click="removeFloor(key, false)">Yes</div>
                    <div class="remove-floor-button" data-id="cancel" @click="cancelRemove(key)">Cancel</div>
                  </div>
                </div>
              </div>

            </div>
        </div>
        <div class="empty-floor" @click="addFloor" v-if="$store.getters.project.role !== 'observer' && !floorPause">
            <div class="empty-floor-text"><span class="empty-floor-plus">+</span>Add an image</div>
        </div>
    </div>
</template>

<script>
  import specDropdown from '@/components/simple/specDropdown.vue'
  import constants from "../../Constants";
  export default {
    'name': 'ProjectFloors',
    components: {
      specDropdown,
    },
    data: ()=>({
      arrayOfObjects: [],
      floorPause: false,
      object: {
        name: 'Select floor',
      },
      floors: [],
      floorNumbers: [],
    }),
    mounted() {
      if(this.$store.getters.project.floors != undefined) {
        for (var i = 0; i < this.$store.getters.project.floors.length; i++) {
          this.arrayOfObjects.push({ name: 'Floor ' + (i + 1), val: i });
        }
      }
      function closeReplases(evt) {
        var path = (evt.composedPath && evt.composedPath()) || evt.path,
          target = evt.target;
        var replaceLists = $('[data-id="replaceList"]');
        var replaceButtons = $('[data-id="replaceButton"]');
        for(var i = 0; i < path.length; i++) {
          for(var n = 0; n < replaceButtons.length; n++) {
            var attr = $(path[i]).attr('data-id');
            if(attr === 'replaceButton') {
              replaceLists.css('display', 'none');
              replaceButtons.css('display', 'flex');
              $(target).parent().find('[data-id="replaceList"]').css('display', 'flex');
              $(target).parent().find('[data-id="replaceButton"]').css('display', 'none');
            }
            if(attr === 'replaceButton' || attr === 'replaceList') {
              return;
            }
          }
        }

        for(var i = 0; i < replaceLists.length; i++) {
          $(replaceLists[i]).css('display', 'none')
        }
        for(var i = 0; i < replaceButtons.length; i++) {
          $(replaceButtons[i]).css('display', 'flex')
        }
      }
      window.removeEventListener('click', closeReplases)
      window.addEventListener('click', closeReplases)
    },
    methods: {
      removeThisFloor(floor_id) {
        this.loaded = true;
        let data = new FormData();
        let user = JSON.parse(localStorage.getItem('maagio_user'));
        let token = localStorage.getItem('token');
        data.append('user_id', user.uid);
        data.append('token', token);
        data.append('floor_id', floor_id);
        let obj = this;
        $.ajax({
          url: constants.BACKEND_URL + 'floor/remove-floor',
          type: 'POST', // важно!
          data: data,
          cache: false,
          dataType: 'json',
          // отключаем обработку передаваемых данных, пусть передаются как есть
          processData: false,
          // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
          contentType: false,
          // функция успешного ответа сервера
          success: function (respond, status, jqXHR) {
            obj.loaded = false;
            if (respond.ok === 1) {
            } else {
              console.log('ОШИБКА: ' + respond.data);
            }
          },
          error: function (jqXHR, status, errorThrown) {
            obj.loaded = false;
            console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
          }
        });
      },
      uploadFloorImage(e) {
        let number = e.target.getAttribute('floor-number');
        this.$store.getters.project.floors[number].imageName = name;
        this.$store.getters.project.floors[number].image_id = '';
        this.$store.dispatch('PROJECT_UPLOAD_FLOOR_PHOTO',
          {floor: this.$store.getters.project.floors[number], file: e.target.files[0]})
      },
      selectNewFloor(element) {
        if (element.selectedOption) {
          let number = element.selectedOption.val;
          let tmp = this.$store.getters.project.floors[number];
          this.$store.getters.project.floors.splice(number, 1,  this.$store.getters.project.floors[element.$attrs.elementid]);
          this.$store.getters.project.floors.splice(element.$attrs.elementid, 1,  tmp);
          for(var i = 0; i < this.$store.getters.project.floors.length; i++) {
            this.$store.dispatch('PROJECT_FLOOR_CHANGE_NUMBER', {floor: this.$store.getters.project.floors[i], number: i+1})
          }
          let floor_1 = {id: this.$store.getters.project.floors[number].id, number: this.$store.getters.project.floors[number].number};
          let floor_2 = {id: tmp.id, number: tmp.number};
          this.loaded = true;
          let data = new FormData();
          let user = JSON.parse(localStorage.getItem('maagio_user'));
          let token = localStorage.getItem('token');
          data.append('user_id', user.uid);
          data.append('token', token);
          data.append('floor_1', JSON.stringify(floor_1));
          data.append('floor_2', JSON.stringify(floor_2));
          this.object = {
            name: 'Select floor'};
          let obj = this;
          $.ajax({
            url: constants.BACKEND_URL + 'floor/update-number',
            type: 'POST', // важно!
            data: data,
            cache: false,
            dataType: 'json',
            processData: false,
            contentType: false,
            success: function (respond, status, jqXHR) {
              obj.loaded = false;
              if (respond.ok === 1) {
              } else {
                console.log('ОШИБКА: ' + respond.data);
              }
            },
            error: function (jqXHR, status, errorThrown) {
              obj.loaded = false;
              console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
            }
          });
          this.closeDropDown();
        }
      },
      addFloor() {
        this.floorPause = true;
        let floor = {
          image: '',
          imageName: '',
          preview: '',
          number: this.$store.getters.project.floors.length,
          units: [],
        };
        this.arrayOfObjects = [];
        for (var i = 0; i < this.$store.getters.project.floors.length; i++) {
          this.arrayOfObjects.push({ name: 'Floor ' + (i + 1), val: i });
        }
        this.createFloor(floor);
      },
      createFloor(floor) {
        this.loaded = true;
        let data = new FormData();
        let user = JSON.parse(localStorage.getItem('maagio_user'));
        let token = localStorage.getItem('token');
        data.append('user_id', user.uid);
        data.append('token', token);
        data.append('project_id', this.$store.getters.project.id);
        data.append('floor_number', floor.number + 1);
        let obj = this;
        $.ajax({
          url: constants.BACKEND_URL + 'floor/create-new-floor',
          type: 'POST', // важно!
          data: data,
          cache: false,
          dataType: 'json',
          // отключаем обработку передаваемых данных, пусть передаются как есть
          processData: false,
          // отключаем установку заголовка типа запроса. Так jQuery скажет серверу что это строковой запрос
          contentType: false,
          // функция успешного ответа сервера
          success: (respond, status, jqXHR) => {
            obj.floorPause = false;
            if (respond.ok === 1) {
                /*obj.published = true;*/
              floor.id = respond.floor.id;
              floor.number = respond.floor.number;
              this.$store.dispatch('PROJECT_FLOOR_ADD', floor)
            }
            else {
              console.log('ОШИБКА: ' + respond.data);
            }
          },
          // функция ошибки ответа сервера
          error: function (jqXHR, status, errorThrown) {
            obj.floorPause = false;
            console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
          }
        });
      },
      uploadImage(key) {
        if(this.$store.getters.project.role !== 'observer') {
          document.getElementById('floor-image-' + key).click();
        }
      },
      closeDropDown: function () {
        let buttons = document.getElementsByClassName('floor-button');
        for (let i = 0; i < buttons.length; i++) {
          buttons[i].style.display = 'flex';
        }
        let selects = document.getElementsByClassName('dropdown-container');
        for (let i = 0; i < selects.length; i++) {
          selects[i].style.display = 'none';
        }
      },
      checkFloorRemove(key){
        let container = document.getElementById(key+'-floor');
        let textContainer = container.querySelector('[data-id="removeInfo"]');
        let unitsCount = this.$store.getters.project.floors[key].units.length;
        if(unitsCount > 0) {
          textContainer.textContent = 'There are units ('+unitsCount+') on the floor. Would you like to delete them?';
        } else {
          textContainer.textContent = 'Would you like to delete this floor?';
        }
        container.style.display = 'flex';
      },
      cancelRemove(key){
        let container = document.getElementById(key+'-floor');
        container.style.display = 'none';
      },
      removeFloor: function (key, flag) {
        this.closeDropDown();
        this.removeThisFloor(this.$store.getters.project.floors[key].id);
       if(flag === true) {
         this.$store.getters.project.floors.splice(key, 1);
       } else {
         for(var i = 0; i < this.$store.getters.project.floors[key].units.length; i++) {
           this.$store.getters.project.unfloor_units.push(this.$store.getters.project.floors[key].units[i]);
         }
         this.$store.getters.project.floors.splice(key, 1);
       }
        this.cancelRemove(key);
      },
    }
  }
</script>
<style lang="less" scoped>

.empty-floor, .project-galleries .empty-floor {
  display: flex;
  margin: 10px;
  width: 397px;
  border-radius: 10px;
  height: 331px;
  align-items: center;
  justify-content: center;
  border: 3px dashed #BDBDBD;
  color: #ABADAE;
  cursor: pointer;
}
.empty-floor-text {
  font-size: 35px;
  display: flex;
  align-items: center;
  color: #ABADAE;
  & .empty-floor-plus {
    text-align: center;
    margin-right: 10px;
    font-size: 51px;
    line-height: 67px;
  }
}
.floor-control {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-right: 25px;
}
.project-floors {
  padding-top: 80px;
  display: flex;
  flex-wrap: wrap;
  & .project-floor {
    display: flex;
    flex-direction: column;
    border: 1px solid #E0E0E0;
    margin: 10px;
    width: 397.2px;
    height: 331px;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 10px;
    color: #4F4F4F;
    & .dropdown-container {
      width: 100%;
      position: absolute;
      left: 0;
      height: 100%;
      padding-right: 20px;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      background-color: #3E4347;
    }
    & .floor-image {
      height: 263px;
      width: 100%;
      & img {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        object-fit: cover;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    & .floor-header {
      color: #ABADAE;
      border-right: 1px solid black;
      margin-right: 10px;
      padding-right: 10px;
      padding-left: 25px;
      width: 300px;
      font-size: 20px;
      flex: none;
      overflow: hidden;
    }
    & .floor-controls {
      height: 65px;
      border-top: 1px solid #828282;
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .remove-floor-controls {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #3E4347;
        display: flex;
        flex-direction: column;
        .remove-buttons {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          font-size: 16px;
          padding-top: 5px;
          .remove-floor-button:hover {
            color: #2F80ED;
          }
          .remove-floor-button {
            cursor: pointer;
            color: #ABADAE;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 8px;
            padding-bottom: 8px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
          }
        }
        .remove-info {
          color: red;
          padding-left: 10px;
          padding-top: 10px;
          font-size: 13px;
        }
      }
      & .floor-select {
        width: 50%;
        display: flex;
        justify-content: center;
        & .floor-select-container {
          width: 50%;
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background-color: white;
          max-height: 120px;
          overflow: auto;
          & .floor-select-item {
            padding-top: 6px;
            padding-bottom: 6px;
            cursor: pointer;
          }
          & .floor-selected {
            width: 100%;
            font-size: 16px;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 8px;
            border-bottom: 1px solid black;
          }
        }
      }
      & .floor-button:hover {
        color: #2F80ED;
      }
      & .floor-button.inactive:hover {
        color: #4F4F4F;
      }
      & .floor-button.inactive {
        opacity: .3;
        cursor: default;
      }
    }
    & .floor-upload-button {
      height: 263px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.remove-icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='23' viewBox='0 0 18 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22943 20.0071C1.22943 21.3826 2.33591 22.508 3.68829 22.508H13.5237C14.8761 22.508 15.9826 21.3826 15.9826 20.0071V5.00178H1.22943V20.0071ZM3.68829 7.50267H13.5237V20.0071H3.68829V7.50267ZM12.909 1.25044L11.6796 0H5.53243L4.303 1.25044H0V3.75133H17.212V1.25044H12.909Z' fill='%23ABADAE'/%3E%3C/svg%3E%0A");
}
.edit-icon {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.5008 6.71975L13.8824 2.14923L15.4038 0.625726C15.8203 0.208575 16.3322 0 16.9392 0C17.5463 0 18.0578 0.208575 18.4736 0.625726L19.995 2.14923C20.4115 2.56638 20.6288 3.06987 20.647 3.65968C20.6651 4.2495 20.4658 4.75262 20.0493 5.16904L18.5008 6.71975ZM16.9251 8.32488L5.40643 19.86H0.788086V15.2351L12.3068 3.69995L16.9251 8.32488Z' fill='%23ABADAE'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}
</style>
