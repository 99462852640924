<template>
  <div class="unit-mark" :style="unitMarkStyle"
       :class="['status-' + item.status, {static: type === 'edit'}]">
    <div class="info">
      <div class="header" :style="{ fontSize: item.unit_mark.font_size * 2 < 8 ? '8px' : item.unit_mark.font_size * 2 + 'px'}"><span :class="{dn: item.unit_mark.natural_width < 100}"></span> {{item.unit_number}}</div>
<!--      <div class="bedroom" v-if="item.unit_mark.natural_width > 60" :style="{fontSize: item.unit_mark.font_size > 9 ? + item.unit_mark.font_size + 'px' : '9px'}">
        {{item.bad}}  {{item.bad > 1? 'Bedrooms': 'Bedroom'}}
      </div>-->
    </div>
<!--    <div class="status" :class="{dn: item.unit_mark.natural_width < 60}">
      <div class="header" :style="{fontSize: item.unit_mark.font_size + 'px'}">{{statuses[item.status]}}</div>
      <div class="button" :style="{width: item.unit_mark.font_size + 'px', height: item.unit_mark.font_size + 'px'}">
        <div class="button-icon"  :style="{width: item.unit_mark.font_size - 5 + 'px', height: item.unit_mark.font_size - 5 + 'px'}"></div>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "unitMark",
  props: {
    type: {
      default() {
        return 'edit';
      }
    },
    item: {
      required: true,
    }
  },
  data() {
    return {
      statuses: ['Available', 'Reserved', 'Sold', 'Future']
    }
  },
  computed: {
    unitMarkStyle() {
      return this.type === 'edit' ? '' : {left: this.item.unit_mark.x + '%', top: this.item.unit_mark.y + '%',
        width: this.type === 'show' ? this.item.unit_mark.natural_width + 'px' : '100%',
        height: this.type === 'show' ? this.item.unit_mark.natural_height + 'px' : '100%',
        fontSize: this.item.unit_mark.font_size === 8 ? + this.item.unit_mark.font_size + 'px' : '8px'};
    }
  },
  methods: {
    actualizeMark() {
      let container = $(this.$el.parentNode.parentNode);
      if(this.item.unit_mark) {
        this.item.unit_mark.natural_width = container.width() / 100 * this.item.unit_mark.width;
        this.item.unit_mark.natural_height = container.height() / 100 * this.item.unit_mark.height;
        let fontSize = this.item.unit_mark.natural_height / 3 / 2;
        let fontSize2 = this.item.unit_mark.natural_width / 8;
        if (fontSize < fontSize2) {
          var min = fontSize;
        } else {
          var min = fontSize2;
        }
        if (min > 5) {
          this.item.unit_mark.font_size = Number.parseInt(min);
        } else {
          this.item.unit_mark.font_size = 5;
        }
      }
    },
    autoActualize() {
      let container = $(this.$el.parentNode.parentNode);
      if(container.width() === 0) {
        setTimeout(()=> {
          this.autoActualize();
        }, 500)
      } else {
        this.actualizeMark();
      }

    }
  },
  mounted() {
    this.autoActualize();
  }
}
</script>

<style scoped>
.header {
  text-align: center;
  padding-top: 0;
}
.number {
  font-size: 24px;
}
.info {
  width: 100%;
  /*padding-left: 3px;
  padding-right: 3px;
  padding-top: 3px;*/
  text-align: right;
}
.button {
  flex: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='38' viewBox='0 0 14 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.59998 2L12 19.0788L1.59998 36.1576' stroke='white' stroke-opacity='0.8' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  width: 10px;
  height: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.unit-mark.status-3 {
  background-color: rgba(117, 117, 117, 0.8);
}
.unit-mark.status-2 {
  background-color: rgba(255, 136, 136, .8);
}
.unit-mark.status-1 {
  background-color: #967bb6dd;
}
.unit-mark.status-0 {
  background-color: #e2a800dd;
}
.status {
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 3px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.unit-mark.static {
  position: static;
}
.unit-mark {
  position: absolute;
  color: white;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bedroom {
  text-align: center;
}
.dn {
  display: none;
}
</style>
