<template>
  <div class="main-container">
<!--    <div class="central-container">
      <div class="overview-header">
        PROJECT STYLE PRESET
      </div>
      <div class="overview-checkboxes">
        <dropdown v-if="$parent.project.template.id != undefined"
                  :options="$parent.project.all_templates"
                  :selected="{val: $parent.project.template.id, name: $parent.project.template.name}"
                  v-on:updateOption="selectNewTemplate"
                  :placeholder="'Select theme'"
                  :closeOnOutsideClick="true"></dropdown>
      </div>
    </div>-->
    <div class="project-overview">
      <div class="overview-container">
        <div class="overview-header">
          PROJECT TITLE *
        </div>
        <input :disabled="$store.getters.project.role === 'observer'" class="overview-input" :value="$store.getters.project.name" :class="{warning: $store.getters.project.name.length < 1}" type="text"
               @keyup="enterName">
        <div class="overview-header" style="margin-top: 30px">
          PROJECT TYPE
        </div>
        <form action="">
          <div class="overview-checkboxes">
            <div class="overview-checkbox" :class="{active: $store.getters.project.house_type == 1}">
              <label @click="() => updateProject('house_type', 1)">
                <div class="overview-checkbox-mask radio-mask">
                  <div class="overview-checkbox-icon"></div>
                </div>
                Condos</label>
            </div>
            <div class="overview-checkbox" :class="{active: $store.getters.project.house_type == 2}">
              <label @click="() => updateProject('house_type',2)">
                <div class="overview-checkbox-mask radio-mask">
                  <div class="overview-checkbox-icon"></div>
                </div>
                Houses</label>
            </div>
          </div>
        </form>
        <div class="overview-header" style="margin-top: 30px">
          RENT OR SELL?
        </div>
        <form action="">
          <div class="overview-checkboxes">
            <div class="overview-checkbox" :class="{active: $store.getters.project.sale == 1}">
              <label @click="() => updateProject('sale', 1)">
                <div class="overview-checkbox-mask radio-mask">
                  <div class="overview-checkbox-icon"></div>
                </div>
                Sell</label>
            </div>
            <div class="overview-checkbox" :class="{active: $store.getters.project.sale == 0}">
              <label @click="() => updateProject('sale', 0)">
                <div class="overview-checkbox-mask radio-mask">
                  <div class="overview-checkbox-icon"></div>
                </div>
                Rent</label>
            </div>
          </div>
        </form>
      </div>
      <div class="overview-container">
        <div class="overview-logo">
          <div class="overview-header">
            PROJECT LOGO
          </div>
          <div class="overview-description">The graphics will appear on the splash screen</div>
          <div :key="project.logo" class="logo-preview pointer" :class="{'no-border': project.logo}" v-on:click="openPhotoUpload()">
            <img v-if="project.logo &&  project.logo != 'unknown'" :src=" project.logo" alt="">
            <div class="logo-preview-text" v-else>
              +Add
            </div>
          </div>
          <input type="file" v-on:change="renderPreview" id="overview-logo" style="display: none"
                 accept="image/png, image/jpeg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import constants from '../../Constants'

  export default {
    'name': 'ProjectOverview',
    components: {

    },
    data: ()=>({
      showMap: true,
      showGallery: true,
      logoPreview: '',
    }),
    watch: {

    },
    computed: {
      project() {
        return this.$store.getters.project;
      },
    },
    mounted() {
      this.$store.dispatch('PROJECT_TAKE');
    },
    methods: {
      selectNewTemplate(e) {
        this.$store.getters.project.template = e;
      },
      updateProject(key, value) {
        if(this.$store.getters.project.role !== 'observer') {
          const update = {};
          update[key] = value;
          this.$store.dispatch('PROJECT_UPDATE', update)
          this.$store.dispatch('PROJECT_SAVE');
        }
      },
      changeUnits() {
        this.$store.getters.project.floors = [];
        if (this.$store.getters.project && this.$store.getters.project.name.length > 0) {
          this.$parent.progresses[1].active = true;
          if (this.$store.getters.project.floors.length > 0) {
            this.$parent.progresses[2].active = true;
          }
        } else {
          this.$parent.progresses[1].active = false;
          this.$parent.progresses[2].active = false;
        }
      },
      openPhotoUpload() {
        if(this.$store.getters.project.role !== 'observer') {
          var container = document.getElementById('overview-logo');
          container.click();
        }
      },
      renderPreview: function (e) {
        this.$store.dispatch('PROJECT_UPLOAD_LOGO', e.target.files[0]);
      },
      enterName(e) {
       this.$store.dispatch('PROJECT_UPDATE', {name: e.target.value});
      },
      changeMap() {
        this.$store.getters.project.map = {lat: '', lng: '', address: ''};
        if (this.showMap === true && this.$store.getters.project.name.length > 0) {
          this.$parent.progresses[3].active = true;
        } else {
          this.$parent.progresses[3].active = false;
        }
      },
      changeGallery() {
        if (this.$store.getters.project.name.length > 0) {
          this.$parent.progresses[4].active = true;
        } else {
          this.$parent.progresses[4].active = false;
        }
      },
    },
  }
</script>
<style scoped lang="less">
.new-project-page {
  padding-top: 40px;
  padding-left: 3%;
  padding-right: 3%;
  margin-left: -3%;
  margin-right: -3%;
  & .project-page-content {
    padding-left: 3%;
    padding-right: 3%;
    margin-bottom: 80px;
  }
  & .new-project-progress {
    display: flex;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 3%;
    & .progress-tab:first-child {
      padding-left: 0;
    }
    & .progress-tab:last-child {
      width: auto;
      padding-right: 0;
    }
    & .progress-tab {
      width: 100%;
      display: flex;
      align-items: center;
      & .progress-name {
        font-family: RobotoRegular, sans-serif;
        font-size: 20px;
        color: #BDBDBD;
        padding-right: 10px;
        padding-left: 10px;
        white-space: nowrap;
        cursor: pointer;
      }
      .done {
        color: #5d9aec;
      }
      &:first-child .progress-name {
        padding-left: 0;
      }
      &:last-child .progress-name {
        padding-right: 0;
      }
      & .progress-arrow {
        width: 100%;
        height: 2px;
        background-color: #BDBDBD;
      }
    }
    & .active {
      & .progress-name {
        color: #2F80ED;
        font-family: RobotoRegular, sans-serif;
        cursor: default;
      }
    }
    & .progress-name.inactive {
      opacity: 0.3;
      cursor: default;
    }
  }
  & .project-page-controls {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    border-top: 1px solid #828282;
    padding-bottom: 30px;
    padding-left: 3%;
    padding-right: 3%;
    & .project-page-subcontrols {
      display: flex;
      flex-wrap: nowrap;
    }
  }
  & .project-page-publish {
    color: #2F80ED;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding-left: 3%;
    padding-right: 3%;
    border-top: 1px solid #828282;
    & .publish-icon {
      display: inline-block;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2222%22%20height%3D%2216%22%20viewBox%3D%220%200%2022%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cpath%20d%3D%22M20.1209%200.00116978C19.7345%200.0134322%2019.3682%200.175795%2019.0995%200.453796C15.4129%204.14845%2011.4666%208.38197%207.78334%2012.1182L2.52565%207.78902C2.37852%207.64484%202.20301%207.53282%202.01027%207.4601C1.81753%207.38738%201.61178%207.35554%201.40607%207.36659C1.20037%207.37764%200.999205%207.43134%200.815366%207.5243C0.631526%207.61725%200.469025%207.7474%200.338182%207.90651C0.207339%208.06563%200.111013%208.25021%200.0553227%208.44854C-0.000367885%208.64688%20-0.0142066%208.85462%200.0146855%209.05858C0.0435777%209.26255%200.114569%209.45828%200.223152%209.63335C0.331734%209.80841%200.475535%209.95898%200.645421%2010.0755L6.95929%2015.2752C7.244%2015.5107%207.60627%2015.6317%207.97538%2015.6145C8.34449%2015.5973%208.69396%2015.4432%208.95558%2015.1823C12.9476%2011.1817%2017.2807%206.49431%2021.2119%202.55457C21.4285%202.34542%2021.5764%202.0754%2021.6361%201.78028C21.6958%201.48516%2021.6644%201.17888%2021.5461%200.902001C21.4278%200.625121%2021.2282%200.390722%2020.9737%200.229852C20.7192%200.0689815%2020.4218%20-0.0107745%2020.1209%200.00116978Z%22%20fill%3D%22%232F80ED%22/%3E%0A%3C/svg%3E%0A');
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      background-position: center;
      background-size: contain;
    }
    & .publish-button {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.project-overview {
  display: flex;
  padding-top: 40px;
  & .overview-container:first-child {
    border-right: 1px solid #828282;
  }
  & .overview-container {
    width: 50%;
    padding-left: 40px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    & .logo-preview.no-border {
      border: 1px solid transparent;
    }
    & .logo-preview {
      border: 1px dashed #ABADAE;
      border-radius: 10px;
      width: 322px;
      height: 274px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
      & img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
  & .overview-header {
    padding-bottom: 15px;
    font-size: 24px;
    line-height: 30px;
    color: #ABADAE;
    font-family: RobotoMedium, sans-serif;
    text-align: center;
  }
  & .overview-input {
    background: #606264;
    border: 1px solid #363C40;
    border-radius: 5px;
    position: relative;
    width: 334px;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
    color: white;
    vertical-align: middle;
    height: 35px;
  }
  & .overview-input.warning {
    border-color: rgba(255, 14, 0, 0.52);
  }
  & .overview-description {
    color: #828282;
    font-size: 14px;
    padding-bottom: 20px;
    margin-top: -10px;
  }
  form {
    width: 100%;
    max-width: 300px;
  }
  & .overview-checkboxes {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }
  & .overview-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.central-container .overview-header {
  font-size: 24px;
  line-height: 30px;
  color: #ABADAE;
  font-family: RobotoMedium, sans-serif;
  text-align: center;
}
.central-container .overview-header {
  padding-bottom: 15px;
}
.central-container .overview-checkboxes {
  display: flex;
  width: 100%;
  justify-content: center;
}
.central-container .overview-checkboxes .btn-group {
  width: 400px;
}
.central-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}
.logo-preview-text {
  font-weight: 500;
  font-size: 35px;
  line-height: 41px;
  color: #606264;
}
</style>
