<template>
  <div id="app">
    <component :is="layout" :key="forced_key">
      <router-view/>
    </component>
  </div>
</template>
<script>
  import ConstructorLayout from '@/layouts/ConstructorLayout';
  import EmptyLayout from '@/layouts/EmptyLayout';
  import ShowLayout from '@/layouts/ShowLayout';
  import db from './IndexedDbHelper.js'
  import helper from './VueHelper.js'
  import constants from "@/Constants";
  const initMap = function () {
    // JS API is loaded and available
    var styledMapType = new google.maps.StyledMapType(constants.MAP_OPTIONS, { name: 'styled_map' });
    window.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: lat, lng: lng },
      zoom: 15,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      mapTypeControlOptions: {
        mapTypeIds: ['styled_map']
      }
    });
    window.map.mapTypes.set('styled_map', styledMapType);
    window.map.setMapTypeId('styled_map');
    var marker = new google.maps.Marker({
      position: { lat: Number.parseFloat(lat), lng: Number.parseFloat(lng) },
      map: window.map,
      icon: constants.BACKEND_URL + '/img/Home.png'
    });
    obj.createMarkers(obj.$parent.project.markers.user_marker);
  };
  var script = document.createElement('script');
  script.src = 'https://js.stripe.com/v3/';
  script.defer = true;
  document.head.appendChild(script);
  var script = document.createElement('script');
script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDjUav2weeX9hAMMcPGPV4cHSJk9DSlql8';
  script.defer = true;
  document.head.appendChild(script);
  var script = document.createElement('script');
  script.src = 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.5.1/gsap.min.js';
  script.defer = true;
  document.head.appendChild(script);
  export default {
    data: function () {
      return {
        forced_key: 0,
      }
    },
    computed: {
      layout() {
       return (this.$route.meta.layout || 'empty') + '-layout'
      }
    },
    components: {
      EmptyLayout, ConstructorLayout, ShowLayout
    },
  }
</script>

<style lang="less">
  @import 'assets/green_template.less';
  @import 'assets/show.less';
  @import 'assets/main.less';
</style>
<style lang="less">
  @import 'assets/normalize.css';
  @import 'assets/brown_template.less';
  @import 'assets/blue_template.less';
  @import 'assets/elegant_template.less';
</style>
