import axios from "axios";
import ProjectOverview from "@/components/edit/ProjectOverview";
import ProjectFloors from "@/components/edit/ProjectFloors";
import ProjectUnits from "@/components/edit/ProjectUnits";
import ProjectMap from "@/components/edit/ProjectMap";
import ProjectGalleries from "@/components/edit/ProjectGalleries";
import ProjectPublish from "@/components/edit/ProjectPublish";


export default {
  state: {
    activeTab: false,
    tabs: [
      {
        name: 'Overview',
        arrow: true,
        active: false,
        done: false,
        widget: ProjectOverview
      },
      {
        name: 'Site Plan',
        arrow: true,
        active: false,
        done: false,
        widget: ProjectFloors
      },
      {
        name: 'Units',
        arrow: true,
        active: false,
        done: false,
        widget: ProjectUnits
      },
      {
        name: 'Maps',
        arrow: true,
        active: false,
        done: false,
        widget: ProjectMap
      },
      {
        name: 'Galleries',
        arrow: true,
        active: false,
        done: false,
        widget: ProjectGalleries
      },
      {
        name: 'Publish',
        arrow: false,
        active: false,
        done: false,
        widget: ProjectPublish
      },
    ]
  },
  getters: {
    activeTab(state) {
      return state.activeTab;
    },
    tabs: (state, getters, rootState, rootGetters) => {
      if(!getters.project) {
        return state.tabs;
      }
      let item = state.tabs[0];
      item.active = true;
      item = state.tabs[1];
      item.active = getters.project?.name.length > 0;
      item = state.tabs[2];
      item.active = getters.project?.name.length > 0 && getters.project?.floors.length > 0;
      state.tabs[3].active = true;
      state.tabs[4].active = true;
      state.tabs[5].active = getters.project?.name.length > 0 && getters.project?.floors.length > 0;
     /* state.tabs[1].name = getters.project.house_type == 2 ? 'Site Plan' : 'Floor Plates';*/
      state.tabs[2].name = 'Units';
      return state.tabs;
    },
    checkName(state) {
      return this.getters.project.name.length > 0;
    },
    checkFloors(state) {
      return this.getters.project.floors.length > 0;
    },
    checkUnits(state) {
      for(let i = 0; i < this.getters.project.floors.length; i++) {
       if(this.getters.project.floors[i].units.length > 0) {
         return true;
       }
      }
      return false;
    },
  },
  mutations: {
    tabs_change(state, payload) {
      state.activeTab = payload;
    }
  },
  actions: {
    TABS_CHANGE(context, payload) {
      context.commit('tabs_change', payload)
    }
  }
}
