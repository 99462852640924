<template>
    <div class="project-galleries">
        <div class="galleries-container">
            <div class="gallery-item" v-for="gallery in $store.getters.project.galleries" :key="gallery.name + gallery.previews + gallery.photos.length">
                <div class="photo-container" v-if="gallery['previews']">
                    <img :src="gallery['previews'][0].image_link" alt="">
                </div>
                <div class="gallery-panel">
                    <div class="gallery-name">{{gallery.name}}</div>
                    <div class="gallery-controls">
                        <div class="gallery-edit" @click="editGallery(gallery)"></div>
                        <div class="gallery-remove" v-if="$store.getters.project.role !== 'observer'" @click="prepareToRemove"></div>
                        <div class="delete-controls" style="display: none" data-id="delete-controls">
                            <div class="delete-message">Do you really want to delete a gallery?</div>
                            <div class="control-buttons">
                                <div class="delete-accept control-button" @click="removeGallery(gallery); cancelGalleryDelete">Yes</div>
                                <div class="delete-decline control-button" @click="cancelGalleryDelete">No</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="empty-floor" v-if="this.$store.getters.project.role !== 'observer'" @click="createNewGallery"><div class="empty-floor-text"><span class="empty-floor-plus">+</span> Add gallery</div></div>
        </div>
        <NewGalleryModal :gallery="tempGallery" v-if="openModal === true"></NewGalleryModal>
    </div>
</template>

<script>
  import NewGalleryModal from '@/components/edit/NewGalleryModal.vue'
  import constants from "../../Constants";
  export default {
    name: 'ProjectGalleries',
    components: {
      NewGalleryModal
    },
    data:()=>({
      galleries: [],
      openModal: false,
      tempGallery: {
        photos: [],
        name: '',
        previews: [],
        changed: false,
      },
    }),
    methods: {
      prepareToRemove(e) {
        let container = e.target.parentNode;
        container = container.querySelector('[data-id="delete-controls"]');
        container.style.display = 'flex';
      },
      cancelGalleryDelete(e){
        let container = e.target.parentNode.parentNode.parentNode;
        container = container.querySelector('[data-id="delete-controls"]');
        container.style.display = 'none';
      },
      createNewGallery() {
        this.tempGallery = {
          photos: [
          ],
          previews: [],
          name: '',
          changed: false,
        };
        this.openModal = true;
      },
      editGallery(gallery) {
        this.tempGallery = gallery;
        this.openModal = true;
      },
      removeGallery(gallery) {
        let data = new FormData();
        let user = JSON.parse(localStorage.getItem('maagio_user'));
        let token = localStorage.getItem('token');
        data.append('user_id', user.uid);
        data.append('token', token);
        data.append('project_id', this.$store.getters.project.id);
        data.append('gallery_id', gallery.id);
        $.ajax({
          url: constants.BACKEND_URL + 'gallery/remove-gallery',
          type: 'POST', // важно!
          data: data,
          cache: false,
          dataType: 'json',
          processData: false,
          contentType: false,
          success: (respond, status, jqXHR) => {
            this.stopSave = false;
            if (respond.ok === 1) {
              for(let i = 0; i < this.$store.getters.project.galleries.length; i++) {
                if(this.$store.getters.project.galleries[i] === gallery) {
                  this.$store.getters.project.galleries.splice(i, 1);
                }
              }
            } else {
              console.log('ОШИБКА: ' + respond.data);
            }
          },
          error: (jqXHR, status, errorThrown) => {
            this.stopSave = false;
            console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
          }
        });
      },
    },
    mounted(){

    }
  }
</script>
<style scoped lang="less">
.galleries-container {
  display: flex;
  flex-wrap: wrap;
  & .photo-container {
    width: 100%;
    text-align: center;
  }
  & .gallery-item {
    display: flex;
    margin: 10px;
    flex-direction: column;
    width: 397px;
    height: 331px;
    align-items: center;
    justify-content: flex-start;
    background: #515558;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    color: #BDBDBD;
    & .empty-floor-text {
      font-size: 40px;
      display: flex;
      align-items: center;
      color: #E0E0E0;
      & .empty-floor-plus {
        text-align: center;
        margin-right: 10px;
        font-size: 51px;
        line-height: 67px;
      }
    }
    & .gallery-panel {
      width: 100%;
      display: flex;
      height: 100%;
      align-items: center;
      position: relative;
      .delete-controls {
        position: absolute;
        margin-top: -12px;
        width: 100%;
        flex-direction: column;
        left: 0;
        height: 50px;
        background-color: #fafafa;
        .delete-message {
          color: red;
          font-size: 12px;
          text-align: center;
        }
        .control-buttons {
          display: flex;
          justify-content: space-between;
        }
        .control-button {
          margin-left: 20px;
          margin-right: 20px;
          cursor: pointer;
        }
        .delete-accept {
          color: red;
        }
        .delete-decline {
          color: green;
        }
      }
      & .gallery-remove {
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2218%22%20height%3D%2224%22%20viewBox%3D%220%200%2018%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cpath%20d%3D%22M1.75%2020.75C1.75%2022.125%202.875%2023.25%204.25%2023.25H14.25C15.625%2023.25%2016.75%2022.125%2016.75%2020.75V5.75H1.75V20.75ZM4.25%208.25H14.25V20.75H4.25V8.25ZM13.625%202L12.375%200.75H6.125L4.875%202H0.5V4.5H18V2H13.625Z%22%20fill%3D%22%23BDBDBD%22/%3E%0A%3C/svg%3E%0A');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background-size: contain;
        cursor: pointer;
      }
      & .gallery-edit {
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cpath%20d%3D%22M0.75%2018.5625V23.25H5.4375L19.2625%209.42495L14.575%204.73745L0.75%2018.5625ZM22.8875%205.79995C23.375%205.31245%2023.375%204.52495%2022.8875%204.03745L19.9625%201.11245C19.475%200.624951%2018.6875%200.624951%2018.2%201.11245L15.9125%203.39995L20.6%208.08745L22.8875%205.79995Z%22%20fill%3D%22%23BDBDBD%22/%3E%0A%3C/svg%3E%0A');
        background-repeat: no-repeat;
        width: 20px;
        margin-right: 20px;
        height: 20px;
        background-size: contain;
        cursor: pointer;
      }
      .elegant-unit {
        display: none;
      }
      & .gallery-name {
        width: 70%;
        text-align: center;
        border-right: 1px solid #828282;
        font-size: 24px;
        color: #ABADAE;
        overflow: hidden;
      }
      & .gallery-controls {
        width: 30%;
        display: flex;
        justify-content: center;
      }
    }
    & img {
      object-fit: cover;
      height: 264px;
      width: 100%;
    }
  }
}
.empty-floor-text {
  font-size: 35px;
  display: flex;
  align-items: center;
  color: #ABADAE;
}
.empty-floor {
  display: flex;
  margin: 10px;
  width: 397px;
  border-radius: 10px;
  height: 331px;
  align-items: center;
  justify-content: center;
  border: 3px dashed #BDBDBD;
  color: #ABADAE;
  cursor: pointer;
}
</style>
