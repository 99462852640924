import { render, staticRenderFns } from "./ShowLayout.vue?vue&type=template&id=5fc50eb4&scoped=true&%3Akey=forced_key&"
import script from "./ShowLayout.vue?vue&type=script&lang=js&"
export * from "./ShowLayout.vue?vue&type=script&lang=js&"
import style0 from "./ShowLayout.vue?vue&type=style&index=0&id=5fc50eb4&prod&scoped=true&lang=css&"
import style1 from "./ShowLayout.vue?vue&type=style&index=1&id=5fc50eb4&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc50eb4",
  null
  
)

export default component.exports