<template>
    <div class="">
        <nav class="navbar">
            <div class="navbar__left">
                <router-link to="/projects" class="logo">MAGGIO</router-link>
            </div>
            <div class="navbar__middle">
                <router-link to="/projects" class="active-link">My projects</router-link>
<!--                <div class="inactive-nav" title="coming soon">Analytics</div>
                <div class="inactive-nav" title="coming soon">FAQ</div>-->
            </div>
            <div class="navbar__right">
<!--                <div class="subscribe-block" v-if="this.$parent.subscribe == null || this.$parent.subscribe.cancel_at_period_end == true">
                    <div v-if="show_subscribe" @click="$parent.open_subscribe = true" class="subscribe-button">Subscribe</div>
                    <div class="end-time-text"> {{$parent.end_time}} days left.</div>
                </div>-->
                <div class="profile-popup" @click="createProject">
                    <div class="new-project">
                        <div class="new-project-icon"></div>
                        <div class="new-project-text">New Project</div>
                    </div>
                </div>
                <!--<div class="new-project">
                    <div class="profile">
                        <div class="profile-icon"></div>
                        <div class="profile-name">{{user_name + ' ' + user_lastname}}</div>
                    </div>
                </div>-->
              <div class="relative profile-popup" data-id="profile-container">
                <div class="new-project" @click="profileOpen = !profileOpen">
                  <div class="new-project-icon profile-icon"></div>
                  <div class="new-project-text">{{user_name + ' ' + user_lastname}}</div>
                </div>
                <div class="profile__list" v-show="profileOpen">
                  <router-link to="/profile" class="new-project-text profile-text">
                    Profile
                  </router-link>
                  <div class="new-project-text logout-text">
                    <span v-if="isLoggedIn" style="padding-right: 10px; cursor: pointer;"><a @click="logout">Logout</a></span>
                  </div>
                </div>
              </div>
              <div class="update-container" v-if="needUpdate == 1" data-id="update-container">
                <div class="dots-icon" v-if="needUpdate == 0"></div>
                <div class="update-icon" v-if="needUpdate == 1" @click="updateOpen = !updateOpen"></div>
                <div class="update-list" v-show="updateOpen">
                    <div class="update-item" v-if="needUpdate == 1" @click="update">Update application</div>
                </div>
              </div>
            </div>
        </nav>
    </div>
</template>

<script>
  import constants from "../../Constants";
  import cardObject from '@/components/simple/cardObject.vue';
  import dropdown from '@/components/simple/dropdown.vue';
    export default {
      'name': 'Navbar',
      computed : {
        isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
        needUpdate() {
          return this.$store.getters.needUpdate;
        },
      },
      components: {
        cardObject, dropdown
      },
      data: ()=>({
        profileOpen: false,
        updateOpen: false,
        user_name: '',
        user_lastname: '',
        end_time: 0,
        show_subscribe: true,
        openSubscribeModal: false,
        actual_tariff: '',
        tariffs: [],
        }),
      methods: {
        createProject() {
          this.$store.dispatch('PROJECT_CREATE_NEW');
          this.$store.dispatch('PROJECT_UPDATE', {name: 'New project'});
          this.$store.dispatch('PROJECT_SAVE').then((project) => {
            this.$router.push(`/edit?project_id=${project.id}`);
          }).catch((error) => {
            console.error(error);
          });
        },
        update() {
          this.$store.dispatch('updateVersionOnline');
        },
        openUpdateContainer(evt) {
          var path = (evt.composedPath && evt.composedPath()) || evt.path;
          for(var i = 0; i < path.length; i++) {
            var dataId = $(path[i]).attr('data-id');
            if(dataId === 'update-container') {
              return;
            }
          }
          this.updateOpen = false;
        },
        openProfileContainer(evt) {
          var path = (evt.composedPath && evt.composedPath()) || evt.path;
          for(var i = 0; i < path.length; i++) {
            var dataId = $(path[i]).attr('data-id');
            if(dataId === 'profile-container') {
              return;
            }
          }
          this.profileOpen = false;
        },
        logout: function () {
          this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
        },
        selectNewTariff(e){
          this.actual_tariff = e;
        },
      },
      beforeDestroy() {
        document.removeEventListener('click', this.openProfileContainer);
        document.removeEventListener('click', this.openUpdateContainer);
      },
      mounted(){
        let user = JSON.parse(localStorage.getItem('maagio_user'));
        this.user_name = user.name;
        if(this.user_lastname != ''){
          this.user_lastname = user.last_name;
        }
        document.addEventListener('click', this.openProfileContainer);
        document.addEventListener('click', this.openUpdateContainer);
      }
    }
</script>
<style scoped lang="less">
.end-time-text {
  color: #ABADAE;
}
.app-constructor-layout .navbar {
  position: absolute;
  z-index: 999;
  width: 100vw;
  padding-top: 16px;
  padding-bottom: 16px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
  background-color: #3E4347;
  .profile-popup {
    .profile {
      cursor: pointer;
    }
    .profile__list {
      border: 1px solid #ABADAE;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      top: 100%;
      z-index: 9;
      background-color: #3E4347;
      min-width: 110px;
      padding: 10px;
      border-radius: 8px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
      .subscribe-block {
        margin-left: 0;
      }
      .new-project {
        padding-bottom: 8px;
      }
    }
  }
  & .logo {
    color: #2C79D3;
    font-size: 30px;
    text-decoration: none;
    font-family: 'RighteousRegular', sans-serif;
  }
  &__left {
    padding-right: 3%;
  }
  &__middle {
    display: flex;
    & .active-link:hover {
      color: #ABADAE;
      opacity: 1;
    }
    & .active-link {
      opacity: .8;
      color: #ABADAE;
      font-weight: 400;
      font-size: 22px;
      line-height: 26px;
      font-family: 'RobotoRegular', sans-serif;
      -webkit-transition: all .3s;
      -moz-transition: all .3s;
      -ms-transition: all .3s;
      -o-transition: all .3s;
      transition: all .3s;
      margin-left: 20px;
      white-space: nowrap;
    }
  }
  &__right {
    margin: 0 0 0 auto;
    display: flex;
    & .new-project {
      display: flex;
      align-items: center;
      & .profile:hover {
        opacity: 1;
      }
      & .profile {
        opacity: .8;
        display: flex;
        align-items: center;
        padding-left: 20px;
      }
      & .new-project-icon.profile-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.31 9.06C2.82 8.67 3.39 8.3624 4.02 8.1372C4.65 7.9124 5.31 7.8 6 7.8C6.69 7.8 7.35 7.9124 7.98 8.1372C8.61 8.3624 9.18 8.67 9.69 9.06C10.04 8.65 10.3126 8.185 10.5078 7.665C10.7026 7.145 10.8 6.59 10.8 6C10.8 4.67 10.3326 3.5374 9.3978 2.6022C8.4626 1.6674 7.33 1.2 6 1.2C4.67 1.2 3.5376 1.6674 2.6028 2.6022C1.6676 3.5374 1.2 4.67 1.2 6C1.2 6.59 1.2976 7.145 1.4928 7.665C1.6876 8.185 1.96 8.65 2.31 9.06ZM6 6.6C5.41 6.6 4.9124 6.3976 4.5072 5.9928C4.1024 5.5876 3.9 5.09 3.9 4.5C3.9 3.91 4.1024 3.4124 4.5072 3.0072C4.9124 2.6024 5.41 2.4 6 2.4C6.59 2.4 7.0876 2.6024 7.4928 3.0072C7.8976 3.4124 8.1 3.91 8.1 4.5C8.1 5.09 7.8976 5.5876 7.4928 5.9928C7.0876 6.3976 6.59 6.6 6 6.6ZM6 12C5.17 12 4.39 11.8424 3.66 11.5272C2.93 11.2124 2.295 10.785 1.755 10.245C1.215 9.705 0.7876 9.07 0.4728 8.34C0.1576 7.61 0 6.83 0 6C0 5.17 0.1576 4.39 0.4728 3.66C0.7876 2.93 1.215 2.295 1.755 1.755C2.295 1.215 2.93 0.7874 3.66 0.4722C4.39 0.1574 5.17 0 6 0C6.83 0 7.61 0.1574 8.34 0.4722C9.07 0.7874 9.705 1.215 10.245 1.755C10.785 2.295 11.2124 2.93 11.5272 3.66C11.8424 4.39 12 5.17 12 6C12 6.83 11.8424 7.61 11.5272 8.34C11.2124 9.07 10.785 9.705 10.245 10.245C9.705 10.785 9.07 11.2124 8.34 11.5272C7.61 11.8424 6.83 12 6 12Z' fill='%23ABADAE'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
.profile-text:hover {
  color: #2C79D3;
}
.inactive-nav {
  opacity: .6;
  color: #ABADAE;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  font-family: 'RobotoRegular', sans-serif;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  margin-left: 50px;
  white-space: nowrap;
}
.logout-text:hover {
  color: white;
}
.profile-text {
  margin-bottom: 10px;
}
.new-project:hover {
  opacity: 1;
}
.new-project-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.88889 8.55556H6.11111V6.11111H8.55556V4.88889H6.11111V2.44445H4.88889V4.88889H2.44445V6.11111H4.88889V8.55556ZM1.22222 11C0.886112 11 0.598279 10.8802 0.358723 10.6407C0.119168 10.4011 -0.00040637 10.1135 1.03754e-06 9.77778V1.22222C1.03754e-06 0.886112 0.119779 0.598279 0.359334 0.358723C0.59889 0.119168 0.886519 -0.00040637 1.22222 1.03754e-06H9.77778C10.1139 1.03754e-06 10.4017 0.119779 10.6413 0.359334C10.8808 0.59889 11.0004 0.886519 11 1.22222V9.77778C11 10.1139 10.8802 10.4017 10.6407 10.6413C10.4011 10.8808 10.1135 11.0004 9.77778 11H1.22222ZM1.22222 9.77778H9.77778V1.22222H1.22222V9.77778Z' fill='%23ABADAE'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 18px;
  display: inline-block;
  margin-right: 5px;
}
.new-project-text {
  transition: all .3s;
  color: #ABADAE;
  font-family: RobotoRegular, sans-serif;
  white-space: nowrap;
}
.profile-popup {
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
}
.new-project {
  transition: all .3s;
  opacity: .8;
}
</style>
