<template :key="forced_key">
  <div class="app-show-layout" :class="$store.getters.project.template?.template_class">
    <main class="app-content">
      <div class="app-page">
        <div class="hidden-menu" id="brown-hidden-menu"
             v-if="activeTab != 'home' && $store.getters.isOnline">
          <div class="header">
            <div class="app-head-name"><span class="close-menu" @click="closeMenu">+</span><span
              class="project-name">{{ $store.getters.project.name }}</span></div>
            <div class="app-head-update">
              <div class="app-head-update__button"
                   @click="$parent.forced_key = !$parent.forced_key">
                Update content
              </div>
              <div class="app-head-update__button" v-if="needUpdate">
                Update application
              </div>
            </div>
          </div>
          <div class="show-nav-tab" @click="changeTab('home')">
            <div class="home-icon"></div>
            <span class="home-text">Home</span></div>
          <div class="show-nav-tab" @click="openFloors"
               :class="{active: activeTab === 'Floor plates', inactive: tabs.floors !== true}">
            <div class="floor-icon info-icon"></div>
            <span class="home-text">Site map</span>
          </div>
          <div class="show-nav-tab" v-if="tabs.units === true" @click="changeTab('units')"
               :class="{active: activeTab === 'Units'}">
            <div class="floor-icon reverse"></div>
            <span class="home-text">Units</span>
          </div>
          <div class="show-nav-tab" @click="changeTab('gallery')"
               :class="{active: activeTab === 'Gallery', inactive: tabs.galleries !== true}">
            <div class="image-icon"></div>
            <span class="home-text">Gallery</span>
          </div>
          <div class="show-nav-tab" @click="changeTab('map')"
               :class="{active: activeTab === 'Contact'}">
            <div class="map-icon"></div>
            <span class="home-text">Address</span></div>
        </div>
        <div class="show-nav" v-if="activeTab != 'home'">
          <div class="show-nav-tab" @click="changeTab('home')">
            <div class="home-icon"></div>
          </div>
          <!--<div class="show-nav-tab"> <div class="welcome-icon"></div></div>-->
          <div class="show-nav-tab" v-if="tabs.floors === true" @click="openFloors"
               :class="{active: activeTab === 'Floor plates'}">
            <div class="floor-icon info-icon"></div>
          </div>
          <div class="show-nav-tab inactive" v-if="tabs.floors !== true">
            <div class="floor-icon"></div>
          </div>
          <div class="show-nav-tab" v-if="tabs.units === true" @click="changeTab('units')"
               :class="{active: activeTab === 'Units'}">
            <div class="floor-icon reverse"></div>
          </div>
          <div class="show-nav-tab" v-if="tabs.galleries === true" @click="changeTab('gallery')"
               :class="{active: activeTab === 'Gallery'}">
            <div class="image-icon"></div>
          </div>
          <div class="show-nav-tab inactive" v-if="tabs.galleries !== true">
            <div class="image-icon"></div>
          </div>
          <div class="show-nav-tab" @click="changeTab('map')"
               :class="{active: activeTab === 'Contact'}">
            <div class="map-icon"></div>
          </div>
          <!--                    <div class="show-nav-tab inactive" v-if="tabs.map !== true"> <div class="map-icon"></div></div>-->
        </div>
        <router-view/>
      </div>
    </main>
  </div>
</template>

<script>
import constants from '../Constants';

export default {
  name: 'show-layout',
  data: () => ({
    forced_key: 0,
    tabs: {
      home: false,
      /*welcome: false,*/
      units: false,
      floors: false,
      galleries: false,
      map: false,
    },
    activeTab: 'home',
  }),
  computed: {
    needUpdate() {
      return this.$store.getters.needUpdate;
    }
  },
  beforeMount(){
    this.loadManifest();
    /*const manifest = document.querySelector('[rel="manifest"]');
    let src = window.location.href.split('/')[4];
    src = constants.BACKEND_URL + 'manifests/' + src + '.json';
    const nextManifest = document.createElement('link');
    nextManifest.id = 'manifest';
    nextManifest.rel = 'manifest';
    nextManifest.href = src;
    manifest.parentNode.replaceChild(nextManifest, manifest);*/
  },
  mounted() {
    this.takeProject();
    this.heartBeat();
    this.heartBeatInterval = setInterval(this.heartBeat, 10000)
  },
  beforeDestroy() {
    clearInterval(this.heartBeatInterval);
  },
  methods: {
    loadManifest() {
      const manifest = document.querySelector('[rel="manifest"]');
      if (!manifest) {
        console.error('Initial manifest not found');
        return;
      }

      let src = window.location.href.split('/')[4];
      src = constants.BACKEND_URL + 'manifests/' + src + '.json';
      const nextManifest = document.createElement('link');
      nextManifest.id = 'manifest';
      nextManifest.rel = 'manifest';
      nextManifest.href = src;
      nextManifest.onload = () => console.log('Manifest loaded successfully');
      nextManifest.onerror = (err) => console.error('Error loading manifest:', err);
      manifest.parentNode.replaceChild(nextManifest, manifest);
    },
    changeTab(tabName) {
      this.$router.push(`/show/${this.$store.getters.project.special_link}/${tabName}`)
      switch (tabName) {
        case 'map':
          this.activeTab = 'Contact'
          break;
        case 'units':
          this.activeTab = 'Units'
          break;
        case 'floors':
          this.activeTab = 'Floor plates'
          this.actualizeMarkers();
          break;
        case 'gallery':
          this.activeTab = 'Gallery'
          break;
        case 'home':
          this.activeTab = 'home'
          break;
        }
      },
    heartBeat() {
      let project = document.location.href.split('show/');
      let data = {project: project[project.length - 1]};
      var obj = this;
      $.ajax({
        url: constants.BACKEND_URL + 'project/take-version',
        type: 'GET',
        data: data,
        dataType: 'json',
        success: function (respond, status, jqXHR) {
          obj.$store.dispatch('set_status', true)
          if (respond.ok == 1) {
            obj.checkVersion(respond.project_id, respond.version);
            obj.checkAppVersion(respond.last_version)
          }
        },
        error: function (error) {
          obj.$store.dispatch('set_status', false)
          console.log(error);
        }
      })
    },
    async checkAppVersion(version) {
      var last_version = await localStorage.getItem('last_version');
      if (last_version !== version) {
        this.$store.dispatch('setNeedUpdate');
        this.$store.dispatch('updateVersion', version);
      }
    },
    checkVersion(project_id, version) {
      var obj = this;
      window.db.getAllValues('project', function (e) {
        var projects = e;
        if (projects.length > 20) {
          projects.sort(function (a, b) {
            return a.value.date - b.value.date;
          });
        }
        for (var i = 0; i < projects.length; i++) {
          if (projects[i].id == project_id) {
            if (projects[i].value.version != version) {
              obj.takeProject();
            }
          }
        }
        return false;
      });
    },
    updateProjectFromCash() {
      let project_link = window.location.href.split('/');
      project_link = project_link[project_link.length - 1];
      var obj = this;
      window.db.getAllValues('project', function (e) {
        var projects = e;
        for (var i = 0; i < projects.length; i++) {
          var proj = projects[i].value;
          if (proj.special_link == project_link) {
            window.db.getValue('project', Number.parseInt(proj.id), function (e) {
              var project_template = e.value;
              project_template.markers.culture = [];
              project_template.markers.restaurant = [];
              project_template.markers.sport = [];
              project_template.markers.nature = [];
              for (var i = 0; i < project_template.floors.length; i++) {
                for (var n = 0; n < project_template.floors[i].units.length; n++) {
                  if (project_template.floors[i].units[n].unit_mark != null) {
                    project_template.floors[i].units[n].show = true;
                    project_template.floors[i].units[n].unit_mark.natural_width = 0;
                    project_template.floors[i].units[n].unit_mark.natural_height = 0;
                  }
                }
              }
              obj.project = project_template;
              obj.checkTabStatus();
            })
          }
        }
      });
    },
    async updateProject(respond) {
      let project = document.location.href.split('show/');
      await this.$store.dispatch('PROJECT_TAKE_BY_LINK', project[project.length - 1]);
      window.db.setValue('project', Number.parseInt(this.$store.getters.project.id), this.$store.getters.project);
    },
    async takeProject() {
      let project = document.location.href.split('/');
      await this.$store.dispatch('PROJECT_TAKE_BY_LINK', project[4]);
      this.tabs.floors = true;
      this.tabs.home = true;
      this.tabs.galleries = true;
      this.tabs.map = true;
      this.tabs.units = true;
      /*window.VueHelper.get(constants.BACKEND_URL + 'project/show?project=' + project[project.length - 1], this.updateProject, this.updateProjectFromCash);*/
    },
    closeMenu(e) {
      $('#brown-hidden-menu').css('left', '-33%')
    },
    checkTabStatus() {
      this.tabs.home = true;
      if (this.$store.getters.project.floors.length > 0) {
        this.tabs.floors = true;
      }
      if (this.$store.getters.project.galleries.length > 0) {
        this.tabs.galleries = true;
      }
      this.tabs.units = true;
      if (this.$store.getters.project.map != '') {
        this.tabs.map = true;
      }
    },

    openFloors() {
      this.activeTab = 'Floor plates';
      this.$router.push(`/show/${this.$store.getters.project.special_link}/floors`)
      $(document).ready(function () {
        $('#floor-image').css('display', 'none');
        $('#floor-image').css('display', 'inline-block');
      })
    }
  }
}
</script>
<style scoped>
.app-content {
  padding-top: 0;
}

.app-content {
  padding-left: 0;
  padding-right: 0;
}
</style>

<style lang="less">
@import '../assets/show.less';
</style>
