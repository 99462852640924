<template>
    <div class="project-maps">
        <div class="map-header">
          MAP
        </div>
      <div class="map-subheader">
        If enabled, Google Maps will show up when the device is
        connected to Wi-Fi.
      </div>
        <div class="overview-checkbox" :class="{active: mapActivate}" v-if="this.$store.getters.project.role !== 'observer'">
            <label for="map-active">
                <div class="toggle-element">
                  <div class="toggle-area" :class="{active: mapActivate}">
                    <div class="toggle-circle"></div>
                  </div>
                </div>
                Enabled</label>
            <input type="checkbox" v-model="mapActivate" v-on:change="changeMapActive" id="map-active"
                   style="display: none">
        </div>
        <div class="markers-block" v-show="this.$store.getters.project.map.lat != '' && this.mapActivate == true">
            <div class="marker-block-button" v-if="$store.getters.project.role !== 'observer'" v-show="!openMarker" @click="openAddMarker">Add marker</div>
            <div class="marker-block" v-show="openMarker">
                <div class="marker-block-form">
                    <div class="marker-header" v-if="$store.getters.project.role !== 'observer'">Add new marker</div>
                    <div class="marker-form-field">
                        <div class="marker-form-column">
                            <div class="marker-field-header">Search</div>
                            <div class="search-block">
                                <input type="text" v-model="userMarker.address" v-on:keyup="searchPlace" class="project-input">
                                <div class="search-results superscroll" v-if="placePredictions.length > 0">
                                    <div class="search-element" v-for="place in placePredictions" :key="place.location" @click="searchOnePlace(place)">{{place.description}}</div>
                                </div>
                            </div>

                        </div>
                        <div class="marker-form-column">
                            <div class="marker-field-header">Name</div>
                            <input type="text" v-model="userMarker.name" class="project-input">
                        </div>
                        <div class="marker-form-column">
                            <div class="marker-field-header">Description</div>
                            <textarea class="project-input superscroll" v-model="userMarker.description" cols="30"
                                      rows="10"></textarea>
                        </div>
                        <div class="marker-field-type">
                            <dropdown :options="markerOption"
                                      v-on:updateOption="selectMarkerType"
                                      :selected="{val: '', name: ''}"
                                      :placeholder="'Select marker type'"
                                      :closeOnOutsideClick="true"></dropdown>
                        </div>
                    </div>
                </div>
                <div class="marker-buttons">
                    <div class="marker-block-button save-button" v-if="$store.getters.project.role !== 'observer'"
                         v-show="openMarker == true && userMarker != '' && markerPause == false && userMarker.marker != ''"
                         @click="addMarker">Save
                    </div>
                    <div class="marker-block-button save-button" style="opacity: 0.3;"
                         v-show="(openMarker == true && userMarker.marker == '')|| markerPause == true">Save
                    </div>
                    <div class="marker-block-button cancel-button" v-show="openMarker == true" @click="cancelMarker">
                        Cancel
                    </div>
                </div>
            </div>
        </div>
        <div class="map-container" v-show="mapActivate">
            <div class="input-container">
                <input :disabled="$store.getters.project.role === 'observer'" v-if="!searchPause" type="text"  @focus="searchAddress" @blur="stopSearchAddress" v-on:keyup="searchAddress"
                       v-model="$store.getters.project.map.address" class="project-input">
                <div class="address-list" v-if="addressPredictions.length > 0">
                    <div class="address-info" v-for="address in addressPredictions" :key="address.description" @click="appendAddress">{{address.description}}</div>
                </div>
                <input v-if="searchPause" type="text" disabled v-model="$store.getters.project.map.address"
                       class="project-input">
                <div class="cssload-container" v-if="searchPause">
                    <div class="cssload-double-torus"></div>
                </div>
            </div>
            <div class="google-map" id="map">

            </div>
        </div>
    </div>
</template>
<script>
  // Create the script tag, set the appropriate attributes
  import constants from "../../Constants";
  import dropdown from '@/components/simple/dropdown.vue'
  import MarkerClusterer from "@googlemaps/markerclustererplus/dist/index.esm";
  export default {
    name: 'ProjectMap',
    components: {
      dropdown,
    },
    props: ['name'],
    data: ()=>({
      mapActivate: true,
      searchingAddress: '',
      addressList: [],
      searchPause: false,
      addressPredictions: [],
      map: '',
      mapMarker: {},
      markerOption: constants.MARKER_TYPES,
      cultureMarker: [],
      sportMarker: [],
      restaurantMarker: [],
      natureMarker: [],
      openMarker: false,
      userMarker: {
        address:'',
        name: '',
        description: '',
        marker: '',
        type: '',
      },
      userMarkerType: '',
      userMarkerIcon: '',
      markerPause: false,
      userMarkers: [],
      placePredictions: [],
    }),
    methods: {

      selectMarkerType(e) {
        this.userMarkerType = e.val;
        this.actualizeMarkerIcon();
      },
      actualizeMarkerIcon() {
        var icon = '';
        if(this.userMarker.marker == '') {
          return false;
        }
        switch(Number.parseInt(this.userMarkerType)) {
          case 1:
            icon = constants.BACKEND_URL +'/img/Cultural.png';
            break;
          case 2:
            icon = constants.BACKEND_URL +'/img/Restaurants.png';
            break;
          case 3:
            icon = constants.BACKEND_URL +'/img/Sports.png';
            break;
          case 4:
            icon = constants.BACKEND_URL +'/img/Nature.png';
            break;
        }
        this.userMarker.marker.setIcon(icon)
      },
      openAddMarker() {
        if (this.openMarker === false) {
          window.mapMarker = window.map.addListener("click", (e)=> {
            placeMarker(e.latLng, map);
          });
          this.openMarker = true;
        } else {
          google.maps.event.clearListeners(map, 'click');
          this.openMarker = false;
        }

        let obj = this;

        function placeMarker(location) {
          var icon = '';
          switch(Number.parseInt(obj.userMarkerType)) {
            case 1:
              icon = constants.BACKEND_URL +'/img/Cultural.png';
              break;
            case 2:
              icon = constants.BACKEND_URL +'/img/Restaurants.png';
              break;
            case 3:
              icon = constants.BACKEND_URL +'/img/Sports.png';
              break;
            case 4:
              icon = constants.BACKEND_URL +'/img/Nature.png';
              break;
          }
          if (obj.userMarker.marker == '') {
            obj.userMarker.marker = new google.maps.Marker({
              position: location,
              map: map,
              icon: icon,
            });
          } else {
            obj.userMarker.marker.setMap(null);
            obj.userMarker.marker = new google.maps.Marker({
              position: location,
              map: map,
              icon: icon,
            });
          }
        }
      },
      addMarker(){
        this.markerPause = true;
        let data = new FormData();
        let user = JSON.parse(localStorage.getItem('maagio_user'));
        let token = localStorage.getItem('token');
        let marker = {};
        marker.lat = this.userMarker.marker.getPosition().lat();
        marker.lng = this.userMarker.marker.getPosition().lng();
        marker.name = this.userMarker.name;
        marker.description = this.userMarker.description;
        marker.type = this.userMarkerType;
        data.append('user_id', user.uid);
        data.append('token', token);
        data.append('project_id', this.$store.getters.project.id);
        data.append('marker', JSON.stringify(marker));
        let obj = this;
        $.ajax({
          url: constants.BACKEND_URL + 'project/add-marker',
          type: 'POST', // важно!
          data: data,
          cache: false,
          dataType: 'json',
          processData: false,
          contentType: false,
          success: function (respond, status, jqXHR) {
            obj.stopSave = false;
          },
          error: function (jqXHR, status, errorThrown) {

          }
        });
        $.ajax({
          url: constants.BACKEND_URL + 'marker/add-marker',
          type: 'POST', // важно!
          data: data,
          cache: false,
          dataType: 'json',
          processData: false,
          contentType: false,
          success: function (respond, status, jqXHR) {
            obj.stopSave = false;
            if (respond.ok === 1) {
              obj.userMarker.marker.id = respond.marker.id;
              obj.$store.getters.project.markers.user_markers.push(obj.userMarker.marker);
              obj.makeInfoWindow(obj.userMarker.marker, respond.marker);
              obj.userMarker = {
                name: '',
                description: '',
                marker: '',
                type: '',
              };
              obj.userMarkerType = '';
              google.maps.event.clearListeners(map, 'click');
              obj.openMarker = false;
              obj.markerPause = false;

            } else {
              console.log('ОШИБКА: ' + respond.data);
              obj.markerPause = false;
            }
          },
          error: function (jqXHR, status, errorThrown) {
            obj.stopSave = false;
            obj.markerPause = false;
            console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
          }
        });
      },
      createInfoWindowDom(info) {
        var container = document.createElement('div');
        container.classList.add('custom-marker');
        var name = document.createElement('div');
        name.classList.add('map-info-name');
        name.textContent = info.name;
        var address = document.createElement('div');
        address.classList.add('map-info-address');
        address.textContent = info.address;
        var description = document.createElement('div');
        description.classList.add('map-info-description');
        description.textContent = info.description;
        var button = document.createElement('div');
        button.classList.add('map-info-button');
        button.textContent = 'Delete marker';
        button.setAttribute('marker-id', info.id);
        button.addEventListener('click', this.deleteMarker);
        container.appendChild(name);
        container.appendChild(address);
        container.appendChild(description);
        container.appendChild(button);
        return container;
      },
      deleteMarker(e) {
        let obj = this;
        let marker_id = e.target.getAttribute('marker-id');
        let user = JSON.parse(localStorage.getItem('maagio_user'));
        let token = localStorage.getItem('token');
        let project_id = window.location.href.split('project_id=');
        project_id = project_id[project_id.length - 1];
        let data = {
          user_id: user.uid,
          token: token,
          project_id: project_id,
          marker_id: marker_id,
        };

        $.ajax({
          url: constants.BACKEND_URL + 'marker/delete-marker',
          type: 'POST', // важно!
          data: data,
          cache: false,
          dataType: 'json',
          success: function (respond, status, jqXHR) {
            if (respond.ok === 1) {
              for (var i = 0; i < obj.$store.getters.project.markers.user_markers.length; i++) {
                if (obj.$store.getters.project.markers.user_markers[i].id == marker_id) {
                  obj.$store.getters.project.markers.user_markers[i].setMap(null);
                }
              }
            }
          },
          // функция ошибки ответа сервера
          error: function (jqXHR, status, errorThrown) {
            console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
          }
        });
      },
      makeInfoWindow(marker, info) {
        if (info.name == null) {
          info.name = '';
        }
        if (info.address == null) {
          info.address = '';
        }
        if (info.description == null) {
          info.description = '';
        }
        window.infowindow = new google.maps.InfoWindow();
        marker.addListener("click", ()=> {
          window.infowindow.setContent(this.createInfoWindowDom(info));
          window.infowindow.open(map, marker);
        });
      },
      cancelMarker(){
        if (this.userMarker.marker != '') {
          this.userMarker.marker.setMap(null);
        }
        this.userMarker.name = '';
        this.userMarker.description = '';
        google.maps.event.clearListeners(map, 'click');
        this.openMarker = false;
      },
      changeMapActive() {
          console.log(this.$store.getters.project.role);
          if(this.$store.getters.project.role !== 'observer') {
              this.$store.getters.project.mapActivate = this.mapActivate;
          }
      },
      mapCenter(lat, lng) {
        if (lat != '' && lng != '') {
          window.map.panTo({ lat: Number.parseFloat(lat), lng: Number.parseFloat(lng) });
        }
      },
      stopSearchAddress() {
        let obj = this;
       setTimeout(function () {
         obj.addressPredictions = [];
        }, 500)
      },
      searchOnePlace(place) {
        let obj = this;
        let place_id = place.place_id;
        let user = JSON.parse(localStorage.getItem('maagio_user'));
        let token = localStorage.getItem('token');
        let data = {
          user_id: user.uid,
          token: token,
          place_id: place_id,
        };
        $.ajax({
          url: constants.BACKEND_URL + 'marker/take-place-by-id',
          type: 'GET', // важно!
          data: data,
          dataType: 'json',
          success: function (respond, status, jqXHR) {
            obj.stopSave = false;
            obj.placePredictions =[];
            if (respond.ok === 1) {
              if(respond.place != '') {
                obj.userMarker.address = respond.place.formatted_address;
                if(obj.userMarker.marker != '') {
                  obj.userMarker.marker.setMap(null);
                }
                obj.userMarker.marker = new google.maps.Marker({
                  position: {
                    lat: Number.parseFloat(respond.place.geometry.location.lat),
                    lng: Number.parseFloat(respond.place.geometry.location.lng)
                  },
                  map: window.map,
                });
                obj.actualizeMarkerIcon();
              }

            }
          },
          error: function (jqXHR, status, errorThrown) {
            obj.stopSave = false;
            console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
          }
        });
      },
      searchPlace() {
        if (this.userMarker.address.length == 0) {
          this.placePredictions = [];
          return false;
        } else if(this.userMarker.address.length < 3) {
          return false;
        }
        if (window.timer) {
          window.clearTimeout(window.timer);
        }
        window.timer = setTimeout( () => {
          let data = new FormData();
          let user = JSON.parse(localStorage.getItem('maagio_user'));
          let token = localStorage.getItem('token');
          data.append('user_id', user.uid);
          data.append('token', token);
          data.append('project_id', this.$store.getters.project.project.id);
          data.append('address', this.userMarker.address);
          $.ajax({
            url: constants.BACKEND_URL + 'map/search-address',
            type: 'POST', // важно!
            data: data,
            cache: false,
            dataType: 'json',
            processData: false,
            contentType: false,
            success: (respond, status, jqXHR) => {
              this.stopSave = false;
              if (respond.ok === 1) {
                for (var i = 0; i < respond.predictions.length; i++) {
                  this.placePredictions = respond.predictions;
                }
              }
            },
            error: (jqXHR, status, errorThrown) => {
              this.stopSave = false;
              console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
            }
          });
        }, 500);
      },
      searchAddress() {
        if(this.$store.getters.project.map == '') {
          this.$store.getters.project.map = {lat: '', lng: '', address: ''};
        }
        if (this.$store.getters.project.map.address.length == 0) {
          this.addressPredictions = [];
          return false;
        } else if(this.$store.getters.project.map.address.length < 3) {
          return false;
        }
        if (window.timer) {
          window.clearTimeout(window.timer);
        }
        window.timer = setTimeout(() => {
          let data = new FormData();
          let user = JSON.parse(localStorage.getItem('maagio_user'));
          let token = localStorage.getItem('token');
          data.append('user_id', user.uid);
          data.append('token', token);
          data.append('project_id', this.$store.getters.project.id);
          data.append('address', this.$store.getters.project.map.address);
          $.ajax({
            url: constants.BACKEND_URL + 'map/search-address',
            type: 'POST', // важно!
            data: data,
            cache: false,
            dataType: 'json',
            processData: false,
            contentType: false,
            success: (respond, status, jqXHR) => {
              this.stopSave = false;
              if (respond.ok === 1) {
                for (var i = 0; i < respond.predictions.length; i++) {
                  this.addressPredictions = respond.predictions;
                }
              }
            },
            error: (jqXHR, status, errorThrown) => {
              this.stopSave = false;
              console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
            }
          });
        }, 500);
      },
      appendAddress(e) {
        this.searchPause = true;
        let data = new FormData();
        let user = JSON.parse(localStorage.getItem('maagio_user'));
        let token = localStorage.getItem('token');
        data.append('user_id', user.uid);
        data.append('token', token);
        data.append('project_id', this.$store.getters.project.id);
        data.append('address', e.target.textContent);
        this.addressPredictions = [];
        $.ajax({
          url: constants.BACKEND_URL + 'map/append-address',
          type: 'POST', // важно!
          data: data,
          cache: false,
          dataType: 'json',
          processData: false,
          contentType: false,
          success: (respond, status, jqXHR) => {
            this.stopSave = false;
            this.searchPause = false;
            if (respond.ok === 1) {
              this.mapCenter(respond.map.lat, respond.map.lng);
              if (this.$store.getters.project.map.marker != undefined) {
                this.$store.getters.project.map.marker.setMap(null);
              }
              this.$store.getters.project.map = respond.map;
              this.$store.getters.project.map.marker = new google.maps.Marker({
                position: {
                  lat: Number.parseFloat(this.$store.getters.project.map.lat),
                  lng: Number.parseFloat(this.$store.getters.project.map.lng)
                },
                map: window.map,
                icon: constants.BACKEND_URL + '/img/Home.png'
              });
            } else {
            }
          },
          error: (jqXHR, status, errorThrown) => {
            this.stopSave = false;
            this.searchPause = false;
            console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
          }
        });
      },
      mapCreated() {
        console.log('Map is on');
      },
      initMap() {
        window.initMap = () => {
          // JS API is loaded and available
          var styledMapType = new google.maps.StyledMapType(constants.MAP_OPTIONS, { name: 'Styled Map' });
          window.map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: constants.DEFAULT_MAP_POSITION.lat, lng: constants.DEFAULT_MAP_POSITION.lng },
            zoom: 15,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
          }, this.mapCreated);
          window.map.mapTypes.set('styled_map', styledMapType);
          window.map.setMapTypeId('styled_map');
          let user = JSON.parse(localStorage.getItem('maagio_user'));
          if (user.ip != undefined && (this.$store.getters.project.map.lng != constants.DEFAULT_MAP_POSITION.lng && this.$store.getters.project.map.lat != constants.DEFAULT_MAP_POSITION.lat)) {
            $.ajax({
              url: 'https://ipwhois.app/json/' + user.ip,
              type: 'GET', // важно!
              dataType: 'json',
              success: (respond, status, jqXHR) => {
                var center = new google.maps.LatLng(respond.latitude, respond.longitude);
                if(!respond.success) {
                  return;
                }
                window.map.panTo(center);
                this.loadMap();
              },
              error: (jqXHR, status, errorThrown) => {
                console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
              }
            });
          }
        };
        window.initMap();
      },
      loadMap(){
        if(this.$store.getters.project.map.lng != undefined && this.$store.getters.project.map.lat != undefined) {
          var center = new google.maps.LatLng(this.$store.getters.project.map.lat, this.$store.getters.project.map.lng);
          window.map.panTo(center);
          var marker = new google.maps.Marker({
            position: {lat: this.$store.getters.project.map.lat, lng: this.$store.getters.project.map.lng},
            map: window.map,
            icon: constants.BACKEND_URL + '/img/Home.png'
          });
          this.$store.getters.project.map.marker = marker;
        }
        for(var i = 0; i < this.$store.getters.project.markers.user_markers.length; i++) {
          this.$store.getters.project.markers.user_markers[i] = this.$parent.createMarker(this.$store.getters.project.markers.user_markers[i]);
        }
        new MarkerClusterer(window.map, this.$store.getters.project.markers.user_markers, {
          imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        });
      },
    },
    mounted() {
      this.markerOption = constants.MARKER_TYPES;
      this.initMap();
    },
  }
</script>
<style scoped lang="less">
.project-maps {
  padding-top: 50px;
  .neardy-marker-buttons {
    height: 64px;
    display: flex;
    justify-content: space-between;
    .overview-checkbox-warning {
      color: #2f89f8;
    }
  }
  .marker-block-button {
    margin-top: 20px;
    -webkit-transition: box-shadow .3s;
    -moz-transition: box-shadow .3s;
    -ms-transition: box-shadow .3s;
    -o-transition: box-shadow .3s;
    transition: box-shadow .3s;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    margin-right: 5px;
    background-color: #2f8bf9;
    display: inline-block;
    color: white;
    cursor: pointer;
  }
  .markers-block {
    padding-bottom: 20px;
  }
  .custom-marker {
    .map-info-name {
      font-family: RobotoMedium, sans-serif;
      font-size: 14px;
      padding-bottom: 5px;
    }
    .map-info-description {
      padding-top: 5px;
    }
    .map-info-button {
      margin-top: 10px;
      color: red;
      font-family: RobotoMedium, sans-serif;
      cursor: pointer;
      display: inline-block;
    }
  }
  .marker-block {
    border: 2px solid #bbbbbb;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    .marker-header {
      padding-bottom: 10px;
      color: #2f8bf9;
      font-size: 18px;
      font-family: RobotoMedium, sans-serif;
    }
    .marker-buttons {
      display: flex;
      .marker-block-button:hover {
        -webkit-box-shadow: 0px 0px 8px 0px rgba(50, 50, 50, 0.75);
        -moz-box-shadow:    0px 0px 8px 0px rgba(50, 50, 50, 0.75);
        box-shadow:         0px 0px 8px 0px rgba(50, 50, 50, 0.75);
      }
      .marker-block-button {
        -webkit-transition: box-shadow .3s;
        -moz-transition: box-shadow .3s;
        -ms-transition: box-shadow .3s;
        -o-transition: box-shadow .3s;
        transition: box-shadow .3s;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 12px;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
      }
      .save-button:hover {
        background-color: #2f8bf9;
      }
      .save-button {
        background-color: #2F80ED;
        color: white;
      }
      .cancel-button {
        background-color: white;
        color: black;
      }
    }
    .marker-block-form {
      .marker-form-field {
        .marker-field-header {
          color: #ABADAE;
          padding-bottom: 5px;
        }
        .marker-form-column {
          padding-top: 10px;
          padding-bottom: 10px;
          .project-input {
            max-width: 300px;
          }
          textarea {
            height: 60px;
            padding-top: 5px;
          }
        }
      }
    }
  }
  & .search-list {
    position: absolute;
    z-index: 99;
    top: 40px;
    background-color: white;
    width: 300px;
    border: 2px solid #BDBDBD;
    border-radius: 8px;
    padding: 10px;
    max-height: 112px;
    overflow-y: auto;
    .address:hover {
      background-color: #e4dfe0;
    }
    .address {
      white-space: nowrap;
      overflow: hidden;
      padding-top: 5px;
      padding-bottom: 5px;
      cursor: pointer;
    }
  }
  & .google-map {
    width: 100%;
    height: 480px;
    margin: 0 auto;
    background: gray;
  }
  & .input-container {
    position: relative;
    top: 20px;
    left: 20px;
    .address-list {
      position: absolute;
      z-index: 999;
      top: 40px;
      background-color: white;
      width: 300px;
      border: 2px solid #BDBDBD;
      border-radius: 4px;
      .address-info:hover {
        background-color: #f0ebec;
      }
      .address-info {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 4px;
        padding-bottom: 4px;
        cursor: pointer;
      }
    }
  }
  & .cssload-container {
    position: absolute;
    width: 26px;
    background-size: contain;
    background-color: white;
    background-position: center;
    height: 34px;
    top: 3px;
    left: 270px;
    z-index: 999;
    display: flex;
    align-items: center;
  }
  & .map-subheader {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #ABADAE;
    padding-bottom: 10px;
  }
  & .map-header {
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    color: #ABADAE;
  }
  & .map-container {
    position: relative;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    overflow: hidden;
    border: 2px solid #E0E0E0;
    & .project-input {
      position: absolute;
      z-index: 999;
      width: 300px;

    }
  }
}
.project-input {
  color: white;
  background: #606264;
  border: 1px solid #363C40;
  border-radius: 5px;
}
.toggle-area.active .toggle-circle {
 left: calc(100% - 18px);
}
.toggle-area.active {
  background-color: #2C79D3;
}
.toggle-area {
  transition: all .3s;
  background: #E7E7E7;
  box-shadow: inset 4px 4px 4px rgba(171, 173, 174, 0.5);
  border-radius: 100px;
  width: 40px;
  height: 20px;
  position: relative;
}
.toggle-circle {
  transition: all .3s;
  position: absolute;
  left: 2px;
  background-color: white;
  box-shadow: inset 4px -4px 4px rgba(0, 0, 0, 0.2);
  top: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.toggle-element {
  margin-right: 10px;
}
</style>
