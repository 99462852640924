<template>
  <div class="container">
    <UnitGalleryModal v-if="unitGalleryModal == true" />
    <div class="project-unit" v-else>
      <div class="unit-create-header">
        <span v-if="$store.getters.project.house_type == 1">UNIT INFO</span>
        <span v-if="$store.getters.project.house_type == 2">Site Plan</span>
      </div>
      <div class="project-unit-create">
        <div class="project-unit-left">
          <div class="unit-subcomponent">
            <div class="subcomponent-label">Unit Number<span class="red" v-if="templateUnit.unit_number == ''">*</span>
            </div>
            <div class="">
              <input :disabled="$store.getters.project.role === 'observer'" type="text" @keyup="numberWarning = false" v-model="templateUnit.unit_number"
                     class="project-input" :class="{warning: numberWarning}">
            </div>
          </div>
          <div class="unit-subcomponent">
            <div class="subcomponent-label">
              {{$store.getters.project.house_type == 1 ? 'Site plan' : 'Unit plan'}}
              <span class="red" v-if="this.templateUnit.floor == ''">*</span>
            </div>
            <dropdown :disabled="$store.getters.project.role === 'observer'" :options="$store.getters.project.role !== 'observer' ? floorOption : []"
                      :selected="{val: '', name: this.$parent.templateUnit.floor}"
                      v-on:updateOption="selectNewFloor"
                      :placeholder="$store.getters.project.house_type == 1 ? 'Select site plan' : 'Unit plan'"
                      :closeOnOutsideClick="true">
            </dropdown>
          </div>
          <div class="unit-subcomponent">
            <div class="subcomponent-label">
              Bedroom
              <span v-if="templateUnit.bad == ''" class="red">*</span>
            </div>
            <div class="">
              <input :disabled="$store.getters.project.role === 'observer'" type="number" min="0" max="99" v-model="templateUnit.bad"
                     class="project-input">
            </div>
          </div>
          <div class="unit-subcomponent">
            <div class="subcomponent-label">Bathroom<span v-if="templateUnit.bath == ''"
                                                          class="red">*</span></div>
            <div class=""><input :disabled="$store.getters.project.role === 'observer'" type="number" min="0" max="99" v-model="templateUnit.bath"
                                 class="project-input"></div>
          </div>
          <div class="unit-subcomponent">
            <div class="subcomponent-label">Price
              <template v-if="$store.getters.project.sale == 0">(Monthly)</template>
              <span v-if="templateUnit.price == ''"
                    class="red">*</span></div>
            <div class=""><input :disabled="$store.getters.project.role === 'observer'" @keyup="priceWarning = false" type="number" min="1"
                                 v-model="templateUnit.price"
                                 :class="{warning: priceWarning}" class="project-input"></div>
          </div>
          <div class="unit-subcomponent" v-if="$store.getters.project.sale == 0">
            <div class="subcomponent-label">Deposit</div>
            <div class=""><input :disabled="$store.getters.project.role === 'observer'" @keyup="priceWarning = false" type="number" min="1"
                                 v-model="templateUnit.deposit"
                                 :class="{warning: priceWarning}" class="project-input"></div>
          </div>
          <div class="unit-subcomponent" v-if="$store.getters.project.house_type == 1">
            <div class="subcomponent-label">Additional info</div>
            <dropdown :options="$store.getters.project.role === 'observer' ? [] : finishes_schemes"
                      :selected="{val: templateUnit.finishes_scheme - 1, name: finishes_schemes[templateUnit.finishes_scheme - 1].name}"
                      v-on:updateOption="selectNewFinishesScheme"
                      :placeholder="'Select finish'"
                      :closeOnOutsideClick="true"></dropdown>
          </div>
          <div class="unit-subcomponent">
            <div class="subcomponent-label">Status<span v-if="templateUnit.status === ''"
                                                        class="red">*</span></div>
            <dropdown v-if="templateUnit.status !== ''" :options="$store.getters.project.role === 'observer' ? [] : statusOption"
                      :selected="{val: templateUnit.status, name: statusOption[templateUnit.status]?statusOption[templateUnit.status].name: statusOption[statusOption.length - 1].name}"
                      v-on:updateOption="selectStatus"
                      :placeholder="'Select status'"
                      :closeOnOutsideClick="true"></dropdown>
            <dropdown v-if="templateUnit.status === ''" :options="$store.getters.project.role === 'observer' ? [] : statusOption"
                      :selected="{val: '', name: ''}"
                      v-on:updateOption="selectStatus"
                      :placeholder="'Select status'"
                      :closeOnOutsideClick="true"></dropdown>
          </div>
          <div class="unit-subcomponent">
            <div class="subcomponent-label">HOA Fees</div>
            <div class=""><input :disabled="$store.getters.project.role === 'observer'" type="number" min="1" v-model="templateUnit.hoa"
                                 class="project-input">
            </div>
          </div>
          <div class="unit-subcomponent" v-if="templateUnit.status == 1">
            <div class="subcomponent-label">Available since</div>
            <div class="">
              <input :disabled="$store.getters.project.role === 'observer'" @keyup="intWarning = false" :min="unixTimestamp(new Date().getTime())"
                     @focusout="changeDate($event.target.value)" type="date"
                     :class="{warning: intWarning}" v-model="formattedDate" class="project-input">
            </div>
          </div>
          <div class="unit-subcomponent">
            <div class="subcomponent-label">Interior sq. ft<span class="red"
                                                                 v-if="templateUnit.int_sq === ''">*</span>
            </div>
            <div class=""><input :disabled="$store.getters.project.role === 'observer'" @keyup="intWarning = false" type="text"
                                 :class="{warning: intWarning}" min="1" step="any"
                                 v-model="templateUnit.int_sq"
                                 class="project-input"></div>
          </div>
          <div class="unit-subcomponent">
            <div class="subcomponent-label">Exterior sq.ft</div>
            <div class=""><input :disabled="$store.getters.project.role === 'observer'" type="text" min="1" step="any" v-model="templateUnit.ext_sq"
                                 class="project-input"></div>
          </div>
          <div class="project-unit-line">
            <div class="unit-subcomponent">
              <div class="overview-checkbox" :class="{active: templateUnit.bmr == 1}">
                <div class="checkbox-container" @click="change('bmr')">
                  <div class="overview-checkbox-mask">
                    <div class="overview-checkbox-icon"></div>
                  </div>
                  BMR
                </div>
              </div>
            </div>
            <div class="unit-subcomponent">
              <div class="overview-checkbox" :class="{active: templateUnit.parking == 1}">
                <div class="checkbox-container" @click="change('parking')">
                  <div class="overview-checkbox-mask">
                    <div class="overview-checkbox-icon"></div>
                  </div>
                  Parking
                </div>
              </div>
            </div>
            <div class="unit-subcomponent">
              <div class="overview-checkbox" :class="{active: templateUnit.den == 1}">
                <div class="checkbox-container" @click="change('den')">
                  <div class="overview-checkbox-mask">
                    <div class="overview-checkbox-icon"></div>
                  </div>
                  Den
                </div>
              </div>
            </div>
            <div class="unit-subcomponent">
              <div class="overview-checkbox" :class="{active: templateUnit.loft == 1}">
                <div class="checkbox-container" @click="change('loft')">
                  <div class="overview-checkbox-mask">
                    <div class="overview-checkbox-icon"></div>
                  </div>
                  Loft
                </div>
              </div>
            </div>
            <div class="unit-subcomponent">
              <div class="overview-checkbox" :class="{active: templateUnit.terrace == 1}">
                <div class="checkbox-container" @click="change('terrace')">
                  <div class="overview-checkbox-mask">
                    <div class="overview-checkbox-icon"></div>
                  </div>
                  Terrace
                </div>
              </div>
            </div>
            <div class="unit-subcomponent">
              <div class="overview-checkbox" :class="{active: templateUnit.outdoor_areas == 1}">
                <div class="checkbox-container" @click="change('outdoor_areas')">
                  <div class="overview-checkbox-mask">
                    <div class="overview-checkbox-icon"></div>
                  </div>
                  Outdoor areas
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="subcomponent-label">
              Description
            </div>
            <textarea :disabled="$store.getters.project.role === 'observer'" class="textarea" name="commentary" v-model="templateUnit.commentary" id=""
                      cols="30" rows="6"></textarea>
          </div>
          <div class="unit-image">
            <div class="unit-image-header">Unit images</div>
            <div class="unit-image-preview" onclick="document.getElementById('image').click()">
              <img
                v-show="templateUnit.unitImagePreview"
                :src="templateUnit.unitImagePreview" alt="" id="unit-preview">
              <div class="unit-no-image pointer"
                   v-if="!templateUnit.unitImagePreview">Upload unit image
              </div>
            </div>
            <div class="unit-image-buttons" v-if="$store.getters.project.role !== 'observer'">
              <input type="file" name="image" @change="uploadImage" style="display: none"
                     accept="image/png, image/jpeg" :id="'image'">
              <div class="remove-image"
                   v-if="templateUnit.unitImagePreview != '' && templateUnit.unitImagePreview != undefined"
                   @click="removeImage">Remove
              </div>
            </div>
          </div>
<!--          <div class="unit-gallery" v-if="templateUnit.photos != undefined && $store.getters.project.role !== 'observer'">
            <div class="unit-gallery-button" @click="openUnitGallery">
              <span v-if="templateUnit.photos != undefined && templateUnit.photos.length == 0">Create gallery</span>
              <span v-if="templateUnit.photos != undefined && templateUnit.photos.length != 0">Edit gallery</span>
            </div>
          </div>-->
          <div class="unit-create-controls">
            <div class="project-page-button" @click="cancel">Back</div>
            <template v-if="$store.getters.project.role !== 'observer'">
              <div class="project-page-button inactive-button" v-if="this.stopSave == true">Save</div>
              <div class="project-page-button blue-button" v-if="this.stopSave == false"
                   @click="makeWarnings">Save
              </div>
            </template>
          </div>
        </div>
<!--        <div class="project-unit-right" v-if="$store.getters.project.house_type == 2">
          <div class="unit-image">
            <div class="unit-image-header">Lot Plan</div>
            <div class="unit-image-preview">
              <img :src="templateUnit.unitImagePreview" id="unit-preview" alt=""
                   v-if="templateUnit.unitImagePreview != '' && templateUnit.unitImagePreview != undefined">
              <div class="floor-no-image" v-else>
                No floor plan
              </div>
            </div>
            <div class="unit-image-buttons">
              <div class="button-container">
                <div class="logo-upload" onclick="document.getElementById('image').click()">Upload
                  photo
                </div>
              </div>
              <input type="file" name="image" @change="uploadImage" style="display: none"
                     accept="image/png, image/jpeg" id="image">
              <div class="remove-image" v-if="templateUnit.unitImagePreview != ''"
                   @click="removeImage">Remove
              </div>
            </div>
          </div>
        </div>-->
        <!--  -->
        <div class="project-unit-right">
          <div class="unit-image-header" :class="{red: this.markWarning == true}"
               v-if="this.templateUnit.floorImage != '' && this.templateUnit.floorImage != null">
                        <span>Unit Location<span class="red"
                                                 v-if="!(this.templateUnit.floorImage != '' && this.templateUnit.floorImage != null && this.templateUnit.mark == true)">*</span> </span>
            Add unit exact location on the map
            <div class="button-container" v-if="templateUnit.mark == true && floorPreview != ''"
                 @click="templateUnit.mark = false; templateUnit.unit_mark.x = 0; templateUnit.unit_mark.y = 0; templateUnit.unit_mark.width = 15; templateUnit.unit_mark.height = 10">
              <div class="logo-upload" v-if="$store.getters.project.role !== 'observer'">Remove unit mark</div>
            </div>
            <div class="button-container" @click="addUnitMark"
                 v-if="templateUnit.mark == false  && floorPreview != ''">
              <div class="logo-upload">Add unit marking</div>
            </div>
          </div>
          <div class="floor-no-image" v-else>
            No floor image
          </div>
          <div class="unit-image-container">
            <vue-draggable-resizable
              v-if="templateUnit.mark == true && templateUnit.unit_mark != null"
              :x="Number.parseInt(templateUnit.unit_mark.x)"
              :y="Number.parseInt(templateUnit.unit_mark.y)"
              :w="Number.parseInt(templateUnit.unit_mark.width)"
              :h="Number.parseInt(templateUnit.unit_mark.height)" @dragging="onDrag"
              @resizing="onResize" :parent="true">
              <unitMark :type="'edit'" :item="templateUnit"
                        v-if="templateUnit.unit_mark != null"></unitMark>

              <!--                            <div :class="'status-' + templateUnit.status" class="unit-point" v-if="templateUnit.unit_mark != null">
                                            <div class="unit-point-number" :style="{fontSize: templateUnit.unit_mark.font_size + 'px'}">
                                              {{templateUnit.unit_number}}
                                            </div>
                                            <div class="unit-point-bedrooms" :style="{fontSize: templateUnit.unit_mark.font_size + 'px'}"
                                                   v-if="templateUnit.bad >= 0 && templateUnit.bad != ''">{{templateUnit.bad}}
                                                  bedroom
                                              </div>
                                              <div class="unit-point-status" v-if="templateUnit.status !== ''" :style="{fontSize: templateUnit.unit_mark.font_size + 'px'}">
                                                  {{statusOption[templateUnit.status].name}}
                                              </div>
                                          </div>-->
            </vue-draggable-resizable>
            <div class="image-block"><img ref="imageRef" id="floor-image" :src="floorPreview" alt=""></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnitGalleryModal from '@/components/edit/UnitGalleryModal.vue'
import dropdown from '@/components/simple/dropdown.vue'
import constants from "../../Constants";
import VueDraggableResizable from 'vue-draggable-resizable'
import unitMark from "../../widgets/simple_components/unitMark";

// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

export default {
  name: 'EditUnitModal',
  components: {
    dropdown, VueDraggableResizable, UnitGalleryModal, unitMark
  },
  props: ['unit'],
  data() {
    return {
      formattedDate: '',
      floorOption: [],
      floorPreview: '',
      stopSave: false,
      finishes_schemes: constants.FINISHES_SCHEMES,
      statusOption: this.$store.getters.project.sale == 1 ? constants.STATUS_OPTIONS : constants.RENT_OPTIONS,
      templateUnit: false,
      counter: 0,  // count the clicks
      timer: null,
      numberWarning: false,
      floorWarning: false,
      bedWarning: false,
      priceWarning: false,
      statusWarning: false,
      bathWarning: false,
      markWarning: false,
      intWarning: false,
      unitGalleryModal: false,
    }
  },
  beforeDestroy() {
    if (this.templateUnit.status === 2 && this.$store.getters.project.sale === 0) {
      this.templateUnit.status = 3;
    }
    document.body.parentNode.style.overflow = '';
  },
  mounted() {
    document.body.parentNode.style.overflow = 'hidden';
  },
  beforeMount() {
    this.templateUnit = JSON.parse(JSON.stringify(this.unit));
    this.finishes_schemes = constants.FINISHES_SCHEMES;
    for (var i = 0; i < this.$store.getters.project.floors.length; i++) {
      this.floorOption.push({name: i + 1, val: ''});
    }
    for(let key in this.$parent.templateUnit) {
      this.templateUnit[key] = this.$parent.templateUnit[key]
    }
    this.templateUnit.unitImagePreview = this.$parent.templateUnit.image;
    this.ChangeFloorPreview();
    let timer = setTimeout( () => {
      this.templateUnit.unit_mark.x = this.calculateX();
      this.templateUnit.unit_mark.y = this.calculateY();
      this.templateUnit.unit_mark.width = this.calculateWidth();
      this.templateUnit.unit_mark.height = this.calculateHeight();
      this.templateUnit.unit_mark.font_size = this.calculateFontSize();
      this.templateUnit.unit_mark.width_prc = this.calculateWidthBack();
      this.templateUnit.unit_mark.height_prc = this.calculateHeightBack();
    }, 500);
    this.formattedDate = this.unixTimestamp(this.templateUnit.available_since);
  },
  methods: {
    changeDate(date) {
      let newDate = new Date(date);
      let today = new Date();
      if (newDate < today) {
        newDate = today;
      }
      this.formattedDate = this.unixTimestamp(newDate.getTime());
      this.templateUnit.available_since = new Date(newDate).getTime();
    },
    unixTimestamp(newVal) {
      let date = new Date(Number.parseInt(newVal));
      let today = new Date();
      if (date < today) {
        date = today;
      }
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    change(name) {
      if(this.$store.getters.project.role === 'observer') {
        return;
      }
      if (this.templateUnit[name] === 1) {
        this.templateUnit[name] = 0;
      } else {
        this.templateUnit[name] = 1;
      }
    },
    saveGallery() {
      var link = '';
      if (this.$store.getters.project.house_type == 1) {
        link = constants.BACKEND_URL + 'unit/update-photos';
      } else {
        link = constants.BACKEND_URL + 'lot-info/update-photos';
      }
      this.stopSave = true;
      let data = new FormData();
      let user = JSON.parse(localStorage.getItem('maagio_user'));
      let token = localStorage.getItem('token');
      data.append('user_id', user.uid);
      data.append('token', token);
      data.append('unit_id', this.templateUnit.id);
      data.append('project_id', this.$store.getters.project.id);
      data.append('photos', JSON.stringify(this.templateUnit.photos));
      for (var i = 0; i < this.templateUnit.photos.length; i++) {
        if (this.templateUnit.photos[i].id == undefined && this.templateUnit.photos[i].image_link != '') {
          data.append('' + i, this.templateUnit.photos[i]);
        }
      }
      let obj = this;
      $.ajax({
        url: link,
        type: 'POST',
        data: data,
        cache: false,
        dataType: 'json',
        processData: false,
        contentType: false,
        success: (respond, status, jqXHR) => {
          obj.stopSave = false;
          if (respond.ok == 1) {
            obj.photos = respond.unit_photos;
            if (obj.$store.getters.project.house_type == 1) {
              for (var i = 0; i < obj.$store.getters.project.floors[obj.templateUnit.floor - 1].units.length; i++) {
                if (obj.$store.getters.project.floors[obj.templateUnit.floor - 1].units[i].id == obj.templateUnit.id) {
                  obj.$store.getters.project.floors[obj.templateUnit.floor - 1].units[i].photos = respond.unit_photos;
                }
              }
            } else {
              obj.$store.getters.project.lot_info.photos = respond.unit_photos;
            }
            obj.resetTemplateUnit();
            obj.$parent.openEditUnit = false;
            this.$store.dispatch('PROJECT_TAKE');
          }
        },
        error: function (jqXHR, status, errorThrown) {
          obj.stopSave = false;
          console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
          obj.resetTemplateUnit();
          obj.$parent.openEditUnit = false;
        }
      });
    },
    makeMore() {

    },
    openUnitGallery() {
      this.unitGalleryModal = true;
    },
    makeWarnings() {
      this.numberWarning = false;
      this.floorWarning = false;
      this.bedWarning = false;
      this.bathWarning = false;
      this.priceWarning = false;
      this.statusWarning = false;
      this.intWarning = false;
      this.markWarning = false;
      var result = true;
      if (this.$store.getters.project.house_type == 1) {
        if (this.templateUnit.unit_number === 0) {
          this.numberWarning = true;
          result = false;
        }
        if (this.templateUnit.floor == '') {
          this.floorWarning = true;
          result = false;
        }
        if (this.templateUnit.bad === '') {
          this.bedWarning = true;
          result = false;
        }
        if (this.templateUnit.bath === '') {
          this.bathWarning = true;
          result = false;
        }
        if (this.templateUnit.price === '') {
          this.priceWarning = true;
          result = false;
        }
        if (this.templateUnit.status === '') {
          this.statusWarning = true;
          result = false;
        }
        if (this.templateUnit.int_sq === '') {
          this.intWarning = true;
          result = false;
        }
        if ((this.templateUnit.floorImage != null && this.templateUnit.floorImage != '') && this.templateUnit.mark != true) {
          this.markWarning = true;
          result = false;
        } else {
          this.markWarning = false;
        }
      } else {
        if (this.templateUnit.unit_number === 0) {
          this.numberWarning = true;
          result = false;
        }
        if (this.templateUnit.bad === '') {
          this.bedWarning = true;
          result = false;
        }
        if (this.templateUnit.bath === '') {
          this.bathWarning = true;
          result = false;
        }
        if (this.templateUnit.price === '') {
          this.priceWarning = true;
          result = false;
        }
        if (this.templateUnit.status === '') {
          this.statusWarning = true;
          result = false;
        }
        if (this.templateUnit.int_sq === '') {
          this.intWarning = true;
          result = false;
        }
      }
      if (!result) {
        $(this.$el).animate({scrollTop: 0}, 600);
      } else {
        this.saveUnit();
      }
    },
    calculateFontSize() {
      let fontSize = this.templateUnit.unit_mark.height / 3 / 2;
      let fontSize2 = this.templateUnit.unit_mark.width / 8;
      if (fontSize < fontSize2) {
        var min = fontSize;
      } else {
        var min = fontSize2;
      }
      if (min > 5) {
        this.$set(this.templateUnit.unit_mark, 'font_size', Number.parseInt(min))
        setTimeout(() => {
          this.templateUnit.unit_mark.font_size = Number.parseInt(min)
        })
        return Number.parseInt(min);
      } else {
        this.$set(this.templateUnit.unit_mark, 'font_size', 5)
        setTimeout(() => {
          this.templateUnit.unit_mark.font_size = Number.parseInt(min)
        })
        return 5;
      }
    },
    calculateX() {
      if (this.templateUnit.unit_mark == null || this.templateUnit.unit_mark.x == undefined) {
        return;
      }
      var imageContainer = $('#floor-image');
      var width = imageContainer.width();
      var percent = width / 100;
      var mark_x = this.templateUnit.unit_mark.x * percent;
      return Number.parseInt(mark_x);
    },
    calculateY() {
      if (this.templateUnit.unit_mark == null || this.templateUnit.unit_mark.y == undefined) {
        return;
      }
      var imageContainer = $('#floor-image');
      var height = imageContainer.height();
      var percent = height / 100;
      var mark_y = this.templateUnit.unit_mark.y * percent;
      return Number.parseInt(mark_y);
    },
    calculateWidth() {
      if (this.templateUnit.unit_mark == null || this.templateUnit.unit_mark.width == undefined) {
        return;
      }
      var imageContainer = $('#floor-image');
      var width = imageContainer.width();
      var percent = width / 100;
      var mark_width = this.templateUnit.unit_mark.width * percent;
      return Number.parseInt(mark_width);
    },
    calculateHeight() {
      if (this.templateUnit.unit_mark == null || this.templateUnit.unit_mark.height == undefined) {
        return;
      }
      var imageContainer = $('#floor-image');
      var width = imageContainer.height();
      var percent = width / 100;
      var mark_height = this.templateUnit.unit_mark.height * percent;
      return Number.parseInt(mark_height);
    },
    calculateXBack() {
      var imageContainer = $('#floor-image');
      var width = imageContainer.width();
      var percent = width / 100;
      var mark_x = this.templateUnit.unit_mark.x / percent;
      return Number.parseFloat(mark_x);
    },
    calculateYBack() {
      var imageContainer = $('#floor-image');
      var height = imageContainer.height();
      var percent = height / 100;
      var mark_y = this.templateUnit.unit_mark.y / percent;
      return Number.parseFloat(mark_y);
    },
    calculateWidthBack() {
      var imageContainer = $('#floor-image');
      var width = imageContainer.width();
      var percent = width / 100;
      var mark_width = this.templateUnit.unit_mark.width / percent;
      return Number.parseFloat(mark_width);
    },
    calculateHeightBack() {
      var imageContainer = $('#floor-image');
      var width = imageContainer.height();
      var percent = width / 100;
      var mark_height = this.templateUnit.unit_mark.height / percent;
      return Number.parseFloat(mark_height);
    },
    onResize: function (x, y, width, height) {
      this.templateUnit.unit_mark.x = x;
      this.templateUnit.unit_mark.y = y;
      this.templateUnit.unit_mark.width = width;
      this.templateUnit.unit_mark.height = height;
      this.templateUnit.unit_mark.font_size = this.calculateFontSize();
      this.templateUnit.unit_mark.x_prc = this.calculateXBack();
      this.templateUnit.unit_mark.y_prc = this.calculateYBack();
      this.templateUnit.unit_mark.width_prc = this.calculateWidthBack();
      this.templateUnit.unit_mark.height_prc = this.calculateHeightBack();
    },
    onDrag: function (x, y) {
      this.templateUnit.unit_mark.x = x;
      this.templateUnit.unit_mark.x_prc = this.calculateXBack();
      this.templateUnit.unit_mark.y = y;
      this.templateUnit.unit_mark.y_prc = this.calculateYBack();
      this.templateUnit.unit_mark.width_prc = this.calculateWidthBack();
      this.templateUnit.unit_mark.height_prc = this.calculateHeightBack();
    },
    resetTemplateUnit() {
      this.templateUnit = JSON.parse(JSON.stringify(constants.STANDART_UNIT));
    },
    ChangeFloorPreview() {
      if (this.templateUnit.floor > 0) {
        this.floorPreview = this.$store.getters.project.floors[this.templateUnit.floor - 1].image;
        this.templateUnit.floorImage = this.$store.getters.project.floors[this.templateUnit.floor - 1].image;
        this.floorPreview = this.$store.getters.project.floors[this.templateUnit.floor - 1].preview;
      }
      /*if (this.$store.getters.project.house_type == 1) {
        if (this.templateUnit.floor > 0) {
          this.floorPreview = this.$store.getters.project.floors[this.templateUnit.floor - 1].image;
          this.templateUnit.floorImage = this.$store.getters.project.floors[this.templateUnit.floor - 1].image;
          this.floorPreview = this.$store.getters.project.floors[this.templateUnit.floor - 1].preview;
        }
      } else {
        if (this.$store.getters.project.lot_info != null) {
          this.templateUnit.unitImagePreview = this.$store.getters.project.lot_info.image;
        }
      }*/
    },
    removeImage() {
      if(this.templateUnit) {
        this.$set(this.templateUnit, 'image', '');
        this.$set(this.templateUnit, 'unitImagePreview', '');
        this.$set(this.templateUnit, 'unitImage', '');
        this.$set(this.templateUnit, 'image_id', '');
      }

      let file = document.getElementById('image');
      file.value = '';
      this.getUnitImage();
    },
    getUnitImage() {
      let img = document.getElementById('unit-preview');
      if (img) {
        if (this.templateUnit.unitImagePreview != '' && this.templateUnit.unitImagePreview != undefined) {
          img.setAttribute('src', this.templateUnit.unitImagePreview);
          return this.templateUnit.unitImagePreview;
        } else {
          img.setAttribute('src', this.templateUnit.image);
          return this.templateUnit.image;
        }
      }
    },
    addUnitMark: function () {
      if (this.$refs.imageRef) {
        const imageWidth = this.$refs.imageRef.offsetWidth;
        const imageHeight = this.$refs.imageRef.offsetHeight;

        const centerX = imageWidth / 2;
        const centerY = imageHeight / 2;

        this.templateUnit.unit_mark = {
          x: centerX,
          y: centerY,
          width: 150,
          height: 150,
          font_size: 1,
        };
        this.templateUnit.unit_mark.font_size = this.calculateFontSize();
        this.templateUnit.mark = true;

      } else {
        console.error('Image not loaded');
      }
    },
    saveUnit() {
      if (this.stopSave == true) {
        return;
      }
      this.stopSave = true;
      let data = new FormData();
      let user = JSON.parse(localStorage.getItem('maagio_user'));
      let token = localStorage.getItem('token');
      if (this.templateUnit.unitImage != '') {
        data.append('image', this.templateUnit.unitImage);
      }
      let unit = this.templateUnit;
      for (var i = 0; i < this.$store.getters.project.floors.length; i++) {
        if (this.$store.getters.project.floors[i].number == this.templateUnit.floor) {
          unit.floor = this.$store.getters.project.floors[i].number;
          unit.floor_id = this.$store.getters.project.floors[i].id;
        }
      }
      unit.unitImagePreview = '';
      data.append('user_id', user.uid);
      data.append('token', token);
      data.append('project_id', this.$store.getters.project.id);
      data.append('house_type', this.$store.getters.project.house_type);
      data.append('unit', JSON.stringify(unit));
      let obj = this;
      $.ajax({
        url: constants.BACKEND_URL + 'unit/update-unit',
        type: 'POST', // важно!
        data: data,
        cache: false,
        dataType: 'json',
        processData: false,
        contentType: false,
        success: function (respond, status, jqXHR) {
          obj.stopSave = false;
          obj.templateUnit.id = respond.unit.id;
          if (respond.ok === 1) {
            if (obj.$store.getters.project.house_type == 2) {
              obj.$store.getters.project.lot_info = respond.unit;
              if (respond.new_image) {
                obj.$store.getters.project.lot_info.image = respond.new_image;
              }
              obj.saveGallery();
              return;
            }
            if (respond.unit.newFloor != undefined) {
              for (var i = 0; i < obj.$store.getters.project.floors.length; i++) {
                for (var n = 0; n < obj.$store.getters.project.floors[i].units.length; n++) {
                  if (obj.$store.getters.project.floors[i].units[n].id == respond.unit.id) {
                    obj.$store.getters.project.floors[i].units.splice(n, 1);
                  }
                }
              }
              obj.$store.getters.project.floors[respond.unit.newFloor - 1].units.push(respond.unit);
            }
            for (var i = 0; i < obj.$store.getters.project.floors[obj.templateUnit.floor - 1].units.length; i++) {
              if (obj.$store.getters.project.floors[obj.templateUnit.floor - 1].units[i].id == respond.unit.id) {
                obj.$store.getters.project.floors[obj.templateUnit.floor - 1].units[i] = respond.unit;
                if (respond.new_image) {
                  obj.$store.getters.project.floors[obj.templateUnit.floor - 1].units[i].image = respond.new_image;
                }
              }
            }
            for (var i = 0; i < obj.$store.getters.project.unfloor_units.length; i++) {
              if (obj.$store.getters.project.unfloor_units[i].id == respond.unit.id) {
                obj.$store.getters.project.floors[obj.templateUnit.floor - 1].units.push(obj.$store.getters.project.unfloor_units[i]);
                obj.$store.getters.project.unfloor_units.splice(i, 1);
              }
            }
            obj.saveGallery();
          } else {
            console.log('ОШИБКА: ' + respond.data);
          }
        },
        error: function (jqXHR, status, errorThrown) {
          obj.stopSave = false;
          console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
        }
      });
    },
    checkSave() {
      if (this.templateUnit.unit_number !== 0 && (this.templateUnit.house_type == 2 || this.templateUnit.floor != '') && this.templateUnit.bad !== ''
        && this.templateUnit.price !== '' && this.templateUnit.status !== '' && this.templateUnit.bath !== ''
        && this.templateUnit.int_sq !== '' && this.stopSave === false) {
        if ((this.templateUnit.floorImage != '' && this.templateUnit.floorImage != null)) {
          if (this.templateUnit.mark != false) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    selectNewFinishesScheme(e) {
      this.templateUnit.finishes_scheme = e.val;
    },
    selectNewFloor(e) {
      this.templateUnit.floor = e.name;
      this.templateUnit.mark = false;
      this.templateUnit.unit_mark = {
        id: '',
        x: 0,
        y: 0,
        width: 15,
        height: 10,
        font_size: 16,
        x_prc: 0,
        y_prc: 0,
        width_prc: 15,
        height_prc: 10,
        font_size_prc: 16,
      };
      this.templateUnit.floor = e.name;
      this.ChangeFloorPreview();
    },
    cancel() {
      this.resetTemplateUnit();
      this.$parent.openEditUnit = false;
    },
    selectStatus(e) {
      switch (e.name) {
        case 'Available':
          this.templateUnit.status = 0;
          break;
        case 'Reserved':
          this.templateUnit.status = 1;
          break;
        case 'Sold':
          this.templateUnit.status = 2;
          break;
        case 'Future':
          this.templateUnit.status = 3;
          break;
      }
    },
    changeColumnDefinition: function (event) {
      let self = this;
      this.counter++;

      if (this.counter === 1) {
        this.timer = setTimeout(function () {
          // DO NOTHING BUT RESET IN CASE THERES JUST ONE CLICK
          self.counter = 0
        }, 300);  // increase delay as you like
      } else {
        let target = event.target.getBoundingClientRect();
        let x = event.clientX - target.left;
        let y = event.clientY - target.top;
        this.templateUnit.unit_mark.x = x;
        this.templateUnit.unit_mark.y = y;
        this.templateUnit.mark = true;
        clearTimeout(this.timer);
        // COLUMN_DEFINITION[c]+=50
        self.counter = 0;
      }
    },
    uploadImage(e) {
      this.file = e.target.files[0];
      let reader = new FileReader();
      let obj = this;
      reader.addEventListener("load", () => {
        obj.$set(obj.templateUnit, 'image', '');
        obj.$set(obj.templateUnit, 'unitImagePreview', reader.result);
        obj.$set(obj.templateUnit, 'unitImage', e.target.files[0]);
        setTimeout(() => {
          document.getElementById('unit-preview').setAttribute('src', this.templateUnit.unitImagePreview);
        }, 100)
        obj.templateUnit.unitImageId = e.target.files[0].name + '_' + e.target.files[0].size;
      }, false);
      if (this.file) {
        if (/\.(jpe?g|png)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    }
  },
}
</script>
<style scoped lang="less">
.container {
  background-color: #3E4347;
  z-index: 9;
  position: absolute;
  width: 100%;
  overflow-x: hidden;
  height: calc(100vh - 72px);
  left: 0;
  top: 72px;
}

.textarea {
  width: 100%;
  border-radius: 8px;
  background: #606264;
  padding: 10px;
  color: white;
  border: 1px solid rgba(54, 60, 64, 0.5);
}

.project-unit {
  padding: 15px 20px;

  & .unit-create-header {
    padding-bottom: 10px;
    color: #ABADAE;
    font-size: 22px;
    font-family: RobotoRegular, sans-serif;
  }

  & .project-unit-create {
    display: flex;
    color: #828282;

    & .subcomponent-label {
      font-family: RobotoMedium, sans-serif;
      padding-bottom: 10px;
    }

    & .project-unit-left {
      background: #515558;
      padding: 10px 20px;
      border: 1px solid #3E4347;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      width: 360px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .unit-subcomponent {
        width: 49%;
        margin-bottom: 20px;

        & .unit-subcomponent {
          display: flex;
          flex-direction: column;
        }
      }

      & .project-unit-line {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
        justify-content: space-between;

        & .overview-checkbox {
          & label {
            color: #828282;
            font-family: RobotoRegular, sans-serif;
          }
        }
      }

      & .checkbox-component {
        display: flex;

        & .overview-checkbox:first-child {
          padding-right: 30px;
        }
      }

      .unit-image {
        width: 100%;
        padding-top: 20px;

        .unit-image-header {
          font-family: RobotoMedium, sans-serif;
        }

        .unit-image-buttons {
          display: flex;
          margin-top: 10px;
          justify-content: center;
        }

        .remove-image {
          cursor: pointer;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 8px;
          padding-bottom: 8px;
        }

        .unit-image-preview {
          min-height: 220px;
          -webkit-border-radius: 12px;
          -moz-border-radius: 12px;
          border-radius: 12px;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        img {
          width: 100%;
          object-fit: contain;
        }

      }

      .unit-gallery {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .unit-gallery-button:hover {
          background-color: #717171;
        }

        .unit-gallery-button {
          text-align: center;
          font-size: 18px;
          display: inline-block;
          white-space: nowrap;
          padding-left: 20px;
          padding-right: 20px;
          margin-left: 20px;
          margin-right: 20px;
          border-radius: 10px;
          padding-top: 8px;
          padding-bottom: 8px;
          background-color: #4F4F4F;
          color: white;
          cursor: pointer;
        }
      }
    }

    & .project-unit-right {
      .unit-image {
        .remove-image {
          cursor: pointer;
          color: red;
        }

        width: 100%;

        .unit-image-buttons {
          display: flex;
          padding-top: 40px;
          padding-bottom: 20px;
          align-items: center;
        }

        .unit-image-header {
          font-size: 24px;
          font-family: RobotoMedium, sans-serif;
        }

        .unit-image-preview {
          img {
            max-width: 600px;
            max-height: 600px;
            object-fit: contain;
          }
        }
      }

      width: calc(100vw - 370px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-left: 40px;
      padding-right: 40px;

      & .unit-image-header {
        padding-bottom: 40px;
        width: 100%;

        & .button-container {
          padding-top: 20px;
        }

        & span {
          font-family: RobotoBlack, sans-serif;
        }
      }

      & .unit-image-container {
        min-width: 760px;
        width: 100%;
        position: relative;
        margin-bottom: 40px;
        background-size: contain;
        background-repeat: no-repeat;
        .image-block {
          width: 100%;
        }
        #floor-image {
          width: 100%;
          max-width: none;
          max-height: none;
        }

        & .unit-point {
          position: absolute;
          width: 100%;
          height: 100%;
          justify-content: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          & .unit-point-status {
            font-family: RobotoBold, sans-serif;
            padding: 0;
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: italic;
            height: auto;
            text-transform: uppercase;
          }

          & .unit-point-number {
            color: black;
            font-family: RobotoBold, sans-serif;
            height: 33%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            transform: scale(2);
          }

          & .unit-point-bedrooms {
            color: black;
            font-family: RobotoBold, sans-serif;
            padding: 0;
            width: 100%;
            text-align: center;
            height: auto;
            display: flex;
            text-transform: uppercase;
            align-items: center;
            justify-content: center;
          }
        }

        & img {
          max-height: 600px;
          -o-object-fit: contain;
          object-fit: contain;
          max-width: 830px;
        }
      }
    }
  }

  & .unit-create-controls {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid transparent;
  }
}

.floor-no-image {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  border-radius: 10px;
  border: 1px dashed #ABADAE;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overview-checkbox-mask {
  width: 26px;
  height: 26px;
  background-color: #606264;
  border: 1px solid rgba(54, 60, 64, 0.5);
  border-radius: 5px;
}

.overview-checkbox.active .overview-checkbox-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.35049 9.60146L6.46959 13.8433C9.41037 9.21818 11.0591 6.62507 13.9999 1.99997' stroke='%232C79D3' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.unit-no-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #E0E0E0;
  width: 100%;
  height: 220px;
  border-radius: 10px;
}

.project-page-button.blue-button:hover {
  background-color: #2e8cf8;
}

.project-page-button.blue-button {
  background-color: #2F80ED;
  color: white;
  border-radius: 5px;
  padding: 5px 20px;
}

.project-page-button {
  background: #606264;
  border: 1px solid rgba(54, 60, 64, 0.5);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 5px 20px;
  color: #ABADAE;
}

.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point {
  border: 1px solid;
}

.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point {
  background-color: white;
}

.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-0 {
  border-color: rgba(0, 0, 255, 0.1);
}

.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-1 {
  border-color: rgba(206, 172, 0, 0.1);
}

.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-2 {
  border-color: rgba(240, 0, 0, 0.1);
}

.unit-point.status-3 {
  border-color: rgba(40, 40, 49, 0.1);
}

.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-2 .unit-point-bedrooms,
.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-2 .unit-point-number,
.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-2 .unit-point-status {
  color: rgba(240, 0, 0, 0.5);
}

.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-1 .unit-point-bedrooms,
.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-1 .unit-point-number,
.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-1 .unit-point-status {
  color: rgba(206, 172, 0, 0.5);
}

.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-0 .unit-point-bedrooms,
.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-0 .unit-point-number,
.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-0 .unit-point-status {
  color: rgba(0, 0, 255, 0.5);
}

.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-3 .unit-point-bedrooms,
.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-3 .unit-point-number,
.project-unit .project-unit-create .project-unit-right .unit-image-container .unit-point.status-3 .unit-point-status {
  color: rgba(40, 40, 49, 0.5);
}
</style>
