<template>
    <div class="fullscreen-modal">
        <div class="new-gallery">
            <div class="unit-create-header">Album Info</div>
            <div class="gallery-create">
                <div class="gallery-name">
                    <div class="gallery-header">
                        <span>Gallery name*</span>
                        <span style="color: red; display: block; font-size: 12px;" v-if="!checkName()">Name already exist. Choose another name</span>
                    </div>
                    <div class=""><input :disabled="$store.getters.project.role === 'observer'" type="text" class="project-input" v-model="gallery.name">
                    </div>
                </div>
                <div class="photos-container">
                    <div class="gallery-header">Add at least one image</div>
                    <div class="gallery-photos">
                        <div class="gallery-photo" v-for="preview in gallery.previews" :key="preview.image_link">
                            <img :src="preview.image_link" alt="">
                            <div class="photo-controls">
                                <div class="remove-icon pointer" v-if="$store.getters.project.role !== 'observer'" @click="removePreview(preview)"></div>
                            </div>
                        </div>
                        <div class="empty-floor" v-if="$store.getters.project.role !== 'observer'" onclick="document.getElementById('image').click()"><div class="empty-floor-text"><span class="empty-floor-plus">+</span> Add</div></div>
                        <form id="upload-image" enctype="multipart/form-data">
                            <div class="form-group">
                                <input type="file" name="image" @change="uploadImage" style="display: none" multiple accept="image/png, image/jpeg" id="image">
                            </div>
                            <input type="submit" style="display: none" class="btn btn-default">
                        </form>
                    </div>
                </div>
            </div>
            <div class="unit-create-controls">
                <div class="project-page-button" @click="cancel">Cancel</div>
              <div class="project-page-button blue-button" @click="saveGallery" v-if="checkSave() && stopSave == false">Save</div>
              <div class="project-page-button inactive-button" v-if="!checkSave() || stopSave == true">Save</div>
            </div>
        </div>
    </div>
</template>

<script>
  import constants from '../../Constants';
  export default {
    name: 'NewGalleryModal',
    data: ()=>({
      upload: false,
      uploadedFiles: [],
      stopSave: false,
      oldGallery: {},
      galleryName: '',
    }),
    props: {
      gallery: {}
    },
    watch: {
      galleryName: function (val) {
        this.galleryName = window.VueHelper.stableInput(val);
        this.gallery.name = this.galleryName;
      },
    },
    methods: {
      checkSave() {
        if(this.$store.getters.project.role === 'observer') {
          return false;
        }
        if(this.checkName() !== false && this.gallery.photos.length > 0 && this.gallery.name !== '') {
          return true;
        } else {
          return false;
        }
      },
      checkName() {
        let galleries = this.$parent.galleries;
        let flag = true;
        for(let i = 0; i < galleries.length; i++) {
          if(this.gallery !== galleries[i]) {
            if(this.gallery.name.toLowerCase() === galleries[i].name.toLowerCase()) {
              flag = false;
            }
          }
        }
       return flag;
      },
      uploadImage(e) {
        for(let i = 0; i < e.target.files.length; i++) {
          this.file = e.target.files[i];
          let reader  = new FileReader();
          reader.addEventListener("load", function () {
            this.gallery.previews.push({number: this.gallery.previews.length, image_link: reader.result, name: e.target.files[i].name});
            this.gallery.photos.push(e.target.files[i]);
          }.bind(this), false);
          if( this.file ){
            if ( /\.(jpe?g|png)$/i.test( this.file.name ) ) {
              reader.readAsDataURL( this.file );
            }
          }
        }
      },
      removePreview(preview) {
        for(let i = 0; i < this.gallery.previews.length; i++) {
          if(this.gallery.previews[i] === preview) {
            this.gallery.photos.splice(i, 1);
            this.gallery.previews.splice(i, 1);
          }
        }
      },
      cancel() {
        for(let i = 0; i < this.$store.getters.project.galleries.length; i++) {
          if(this.$store.getters.project.galleries[i] === this.gallery) {
            this.$store.getters.project.galleries[i] = this.oldGallery;
            break;
          }
        }
        this.gallery = this.oldGallery;
        this.$parent.tempGallery = this.oldGallery;
        this.$parent.openModal = false;
      },
      closeModal() {
        this.$parent.openModal = false;
      },
      saveGallery() {
        if(this.checkSave()) {
          this.stopSave = true;
          let data = new FormData();
          let user = JSON.parse(localStorage.getItem('maagio_user'));
          let token = localStorage.getItem('token');
          data.append('user_id', user.uid);
          data.append('token', token);
          data.append('project_id', this.$store.getters.project.id);
          let gallery = JSON.parse(JSON.stringify(this.gallery));
          gallery.previews = '';
          for(var i = 0; i < gallery.photos.length; i++) {
            if((gallery.photos[i].id == undefined && gallery.photos[i].image_link != '') && !gallery.photos[i].uploaded) {
              data.append(''+i,this.gallery.photos[i]);
            }
          }
          data.append('gallery', JSON.stringify(gallery));
          let obj = this;
          $.ajax({
            url: constants.BACKEND_URL + 'gallery/update-gallery',
            type: 'POST', // важно!
            data: data,
            cache: false,
            dataType: 'json',
            processData: false,
            contentType: false,
            success: (respond, status, jqXHR) => {
              obj.stopSave = false;
              obj.$parent.openModal = false;
              if (respond.ok === 1) {
                if(respond.gallery.id) {
                  obj.gallery.id = respond.gallery.id;
                  obj.gallery.preview = respond.gallery.photos[0].image_link;
                }
                obj.gallery.photos = [];
               for(var i = 0; i < respond.gallery.photos.length; i++) {
                 obj.gallery.photos.push(respond.gallery.photos[i]);
               }
               var flag = false;
               for(var i = 0; i < obj.$store.getters.project.galleries.length; i++) {
                 if(obj.$store.getters.project.galleries[i].id === obj.gallery.id) {
                   flag = true;
                 }
               }
               if(!flag) {
                 obj.$store.getters.project.galleries.push(obj.gallery);
               }
                /*for(var i =0; i < obj.$store.getters.project.galleries.length; i++) {
                  if(obj.$store.getters.project.galleries[i].id == respond.gallery.id) {
                    for(var n = 0; n < obj.$store.getters.project.galleries[i].photos.length; n++) {
                      obj.$store.getters.project.galleries[i].photos[n].uploaded = true;
                    }
                    return;
                  }
                }*/
                for(var i = 0; i < obj.gallery.photos.length; i++) {
                  obj.gallery.photos[i].uploaded = true;
                }
              } else {
                console.log('ОШИБКА: ' + respond.data);
              }
            },
            error: function (jqXHR, status, errorThrown) {
              obj.stopSave = false;
              console.log('ОШИБКА AJAX запроса: ' + status, jqXHR);
            }
          });
        }
      },
      removePhoto(el) {
        for(let i = 0; i < this.gallery.photos.length; i++) {
          if(el === this.gallery.photos[i]) {
            this.gallery.photos.splice(i, 1);
          }
        }
      }
    },
    mounted() {
        this.gallery = this.$parent.tempGallery;
        this.oldGallery = JSON.parse(JSON.stringify(this.$parent.tempGallery));
    },
  }
</script>
<style scoped lang="less">
.project-page-button {
  margin-right: 10px;
}
.new-gallery {
  background-color: #fafafa;
  padding: 40px;
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  & .unit-create-header {
    color: #2F80ED;
    font-size: 24px;
    font-family: RobotoMedium, sans-serif;
  }
  & .gallery-create {
    padding-top: 40px;
    & .gallery-header {
      font-size: 20px;
      padding-bottom: 20px;
      color: #ABADAE;
      span {
        color: #ABADAE;
        font-family: RobotoMedium, sans-serif;
      }
    }
    & .project-input {
      max-width: 320px;
    }
    & .photos-container {
      padding-top: 30px;
      margin-left: -20px;
      margin-right: -20px;
      & .gallery-header {
        padding-bottom: 0;
        padding-left: 20px;
      }
      & .gallery-photos {
        display: flex;
        flex-wrap: wrap;
      }
      & .empty-floor {
        margin: 10px;
      }
      & .gallery-photo img {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 263px;
        cursor: pointer;
      }
      & .test-photo {
        background-image: url("/src/assets/img/no-image-project.png");
        width: 100%;
        height: 160px;
      }
      & .gallery-photo {
        display: flex;
        flex-direction: column;
        border: 1px solid #E0E0E0;
        margin: 10px;
        width: 397.2px;
        height: 331px;
        align-items: flex-start;
        justify-content: flex-start;
        border-radius: 10px;
        color: #4F4F4F;
        & .photo-controls {
          height: 65px;
          border-top: 1px solid #828282;
          position: relative;
          width: 100%;
          flex: none;
          padding-right: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          & .photo-controls-button:hover {
            color: #747474;
          }
          & .photo-controls-button {
            width: 50%;
            text-align: center;
            cursor: pointer;
            font-family: RobotoMedium, sans-serif;
          }
        }
      }
    }
  }
}
.new-gallery {
  background: #515558;
}
.empty-floor {
  display: flex;
  margin: 10px;
  width: 397px;
  border-radius: 10px;
  height: 331px;
  align-items: center;
  justify-content: center;
  border: 3px dashed #BDBDBD;
  color: #ABADAE;
  cursor: pointer;
}
.project-page-button {
  margin-left: 0;
}
.empty-floor-text {
  font-size: 35px;
  display: flex;
  align-items: center;
  color: #ABADAE;
  & .empty-floor-plus {
    text-align: center;
    margin-right: 10px;
    font-size: 51px;
    line-height: 67px;
  }
}
.remove-icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='23' viewBox='0 0 18 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22943 20.0071C1.22943 21.3826 2.33591 22.508 3.68829 22.508H13.5237C14.8761 22.508 15.9826 21.3826 15.9826 20.0071V5.00178H1.22943V20.0071ZM3.68829 7.50267H13.5237V20.0071H3.68829V7.50267ZM12.909 1.25044L11.6796 0H5.53243L4.303 1.25044H0V3.75133H17.212V1.25044H12.909Z' fill='%23ABADAE'/%3E%3C/svg%3E%0A");
}
</style>
